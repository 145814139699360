import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Invoice } from '@app/models/Invoice.model';
import { Originator } from '@app/models/Originator.model';
import { HttpHeaders } from '@angular/common/http';
import { MailerService } from '@app/mailer/mailer.service';

@Component({
  selector: 'invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {
  @Input() walletId: string;
  @Input() walletName: string;
  @Input() wallet: any;
  @Input() invoiceToModify: Invoice;
  @Input() originator: Originator;

  invoiceCreationForm!: FormGroup;
  isEditing: boolean;
  isLoading = false;
  rate: number = 100;
  passwordVerify: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private firebase: FirebaseTranchedService,
    private sendMail: MailerService
  ) {}

  ngOnInit() {
    this.createForm();
    this.firebase.getAttualUsuryRates().subscribe((data: any) => {
      this.rate = data[0].amount;
    });

    console.log('Comany=>', this.firebase.loggedUser.assignorName);
    console.log('Wallet=>', this.wallet.originatorUId);
    this.firebase.getUserByID(this.wallet.originatorUId).then(data => {
      if (data.exists) {
        this.originator = data.data() as Originator;
        console.log('Wallet=>', this.wallet);
        console.log('originator=>', this.originator);
      } else {
        console.log('DAta vuoto');
      }
    });
  }

  createForm() {
    let validatorsImportoFattura: any[];
    if (this.wallet.maxInvoice) {
      validatorsImportoFattura = [
        Validators.required,
        Validators.min(this.wallet.minInvoice),
        Validators.max(this.wallet.maxInvoice),
        this.importoTooBig()
      ];
    } else {
      validatorsImportoFattura = [Validators.required, Validators.min(this.wallet.minInvoice)];
    }

    this.invoiceCreationForm = this.formBuilder.group({
      numFattura: new FormControl('', [Validators.required]),
      importoFattura: new FormControl('', validatorsImportoFattura),
      dataFattura: new FormControl('', [Validators.required]),
      dataScadenza: new FormControl('', [Validators.required]),
      scontoProposto: new FormControl('', [Validators.required, Validators.min(0.0)]),
      importoDaScontare: new FormControl('', [Validators.min(0.0)]),
      rate: new FormControl(false)
    });

    if (this.isEditing) {
      this.invoiceCreationForm.setValue({
        numFattura: this.invoiceToModify.numFattura,
        importoFattura: this.invoiceToModify.importoFattura,
        dataFattura: this.invoiceToModify.dataFattura,
        dataScadenza: this.invoiceToModify.dataScadenza,
        scontoProposto: this.invoiceToModify.scontoProposto,
        importoDaScontare: (this.invoiceToModify.importoFattura * this.invoiceToModify.scontoProposto) / 100,
        rate: 100
      });
    }
  }

  importoTooBig(): ValidatorFn {
    // check if inserted value is contained in a (plafond - counter) of a enabled product
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return (this.wallet.productsNode[0].enabled &&
        control.value <= this.wallet.productsNode[0].plafond - this.wallet.productsNode[0].counter) ||
        (this.wallet.productsNode[1].enabled &&
          control.value <= this.wallet.productsNode[1].plafond - this.wallet.productsNode[1].counter) ||
        (this.wallet.productsNode[2].enabled &&
          control.value <= this.wallet.productsNode[2].plafond - this.wallet.productsNode[2].counter)
        ? null
        : { importoTooBig: true };
    };
  }

  submit() {
    if (!this.invoiceCreationForm.valid) {
      this.invoiceCreationForm.get('numFattura').markAsTouched();
      this.invoiceCreationForm.get('importoFattura').markAsTouched();
      this.invoiceCreationForm.get('dataFattura').markAsTouched();
      this.invoiceCreationForm.get('dataScadenza').markAsTouched();
      this.invoiceCreationForm.get('scontoProposto').markAsTouched();
      this.invoiceCreationForm.get('importoDaScontare').markAsTouched();
    } else {
      this.isLoading = true;
      if (!this.isEditing) {
        this.invoiceCreationForm.controls.rate.setValue(this.invoiceCreationForm.value.scontoProposto <= this.rate);
        this.firebase
          .createInvoice(this.invoiceCreationForm, this.walletId, this.walletName)
          .then(() => {
            // invio email di notifica
            // creo il body dell'email

            //
            const body =
              '{\n' +
              '    "to":"' +
              this.originator.email +
              '",\n' +
              '    "firstname":"' +
              this.originator.originatorSolicitorName +
              '",\n' +
              '    "lastname":"' +
              this.originator.originatorSolicitorSurname +
              '",\n' +
              '    "subject":"Richiesta di anticipazione fattura",\n' +
              '    "senderCompany":"FX12 Srl",\n' +
              '    "from":"support@incassaora.it",\n' +
              '    "senderName":"Support of Incassaora",\n' +
              '    "templateName":"advanceRequest",\n' +
              '    "denominazioneCedente":"' +
              this.firebase.loggedUser.assignorName +
              '",\n' +
              '    "importoFattura":"' +
              this.invoiceCreationForm.value.importoFattura +
              '",\n' +
              '    "dataFattura":"' +
              this.invoiceCreationForm.value.dataFattura +
              '",\n' +
              '    "numeroFattura":"' +
              this.invoiceCreationForm.value.numFattura +
              '",\n' +
              '    "maturity":"' +
              this.invoiceCreationForm.value.dataScadenza +
              '",\n' +
              '    "scontoProposto":"' +
              this.invoiceCreationForm.value.scontoProposto +
              '"\n' +
              '}';

            console.log('body=>', body);

            this.sendMail.sendEmailTemplateByBody(body);

            this.activeModal.dismiss();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            alert("Errore nell'inserimento della fattura");
          });
      } else {
        this.invoiceToModify.numFattura = this.invoiceCreationForm.value.numFattura;
        this.invoiceToModify.dataFattura = this.invoiceCreationForm.value.dataFattura;
        this.invoiceToModify.dataScadenza = this.invoiceCreationForm.value.dataScadenza;
        this.invoiceToModify.importoFattura = this.invoiceCreationForm.value.importoFattura;
        this.invoiceToModify.scontoProposto = this.invoiceCreationForm.value.scontoProposto;

        this.firebase
          .modInvoice(this.invoiceToModify)
          .then(() => {
            this.activeModal.dismiss();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    }
  }

  // getters
  get numFattura() {
    return this.invoiceCreationForm.get('numFattura');
  }

  get importoFattura() {
    return this.invoiceCreationForm.get('importoFattura');
  }

  get dataFattura() {
    return this.invoiceCreationForm.get('dataFattura');
  }

  get dataScadenza() {
    return this.invoiceCreationForm.get('dataScadenza');
  }

  get scontoProposto() {
    return this.invoiceCreationForm.get('scontoProposto');
  }
}
