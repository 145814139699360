export class User {
  static tableName = 'Users';
  id: string;
  roles: string[];
  name: string;
  surname: string;
  email: string;
  password: string;
  originatorProducts: boolean[];
  passwordDate: string;
  verified = true;
  telefono: string;
  indirizzo: string;
  stato: string;
  constructor() {}
}

export class UserCredentials {
  password: string;

  constructor() {}
}
