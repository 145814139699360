import { AngularFireAuth } from '@angular/fire/auth';
import { Utilities } from '@app/utilities/Utilities';
import { Component, OnInit, Input } from '@angular/core';

import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { requireCheckboxesToBeCheckedValidator } from '@app/models/utils/require-checkboxes-to-be-checked.validator';
import { ConfirmationModalComponent } from '@app/tigran/confirmation-modal/confirmation-modal.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-modal-profile',
  templateUrl: './modal-profile.component.html',
  styleUrls: ['./modal-profile.component.scss']
})
export class ModalProfileComponent implements OnInit {
  profileModForm!: FormGroup;
  profileAssModForm!: FormGroup;
  profileOriModForm!: FormGroup;
  profilePartModForm!: FormGroup;
  profileServicerModForm!: FormGroup;
  profileInvestorModForm!: FormGroup;
  assignorMod: any;
  originatorMod: any;
  partnerMod: any;
  investorMod: any;
  servicerMod: any;
  callTigran: boolean;

  userCredential: boolean;
  @Input() userInput: any;
  userToMod: any;
  sessione: boolean;
  userType: string[] = Utilities.userType;
  products: string[] = Utilities.productNames;
  userProducts: string[];

  tigranLoading = false;
  showUpdateButton = true;

  constructor(
    public firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public auth: AngularFireAuth
  ) {
    this.callTigran = false;
  }

  ruoloMaggiore(role: string[]): string {
    if (role.includes('originator')) {
      return 'originator';
    }
    if (role.includes('partner')) {
      return 'partner';
    }
    if (role.includes('investor')) {
      return 'investor';
    }
    if (role.includes('company')) {
      return 'company';
    }
    if (role.includes('servicer')) {
      return 'servicer';
    }
  }

  ngOnInit() {
    if (this.userInput) {
      this.sessione = false;
      this.userToMod = this.userInput;
    } else {
      this.sessione = true;
      this.userToMod = this.firebase.loggedUser;
    }
    let role = this.ruoloMaggiore(this.userToMod.roles);

    if (role == this.userType[3]) {
      this.assignorMod = this.userToMod;
      this.createFormModCedente();
    } else if (role == this.userType[2]) {
      this.originatorMod = this.userToMod;
      this.userProducts = this.originatorMod.originatorProducts;
      this.createFormModOriginator();
    } else if (role == this.userType[4]) {
      this.partnerMod = this.userToMod;
      this.createFormModPartner();
    } else if (role == this.userType[5]) {
      this.investorMod = this.userToMod;
      this.createFormModInvestor();
    } else if (role == this.userType[6]) {
      this.servicerMod = this.userToMod;
      this.createFormModServicer();
    }
  }

  createFormModOriginator() {
    this.profileOriModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.email]),
      email: new FormControl('', [Validators.required]),
      originatorPec: new FormControl('', [Validators.required]),
      originatorPIva: new FormControl('', [Validators.required]),
      phone_number: new FormControl(''),
      originatorMoreScore: new FormControl(''),
      lastTigranDate: new FormControl(''),
      originatorDefault: new FormControl(''),
      originatorWebSite: new FormControl('', [Validators.required]),
      originatorCreditsCheck: new FormControl(false),
      // prodotti
      originatorProducts: new FormGroup(
        {
          originatorFintech: new FormControl(false),
          originatorSupply: new FormControl(false),
          originatorBlue: new FormControl(false)
        },
        requireCheckboxesToBeCheckedValidator()
      ),
      originatorOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      originatorOfficeRegisteredCity: new FormControl('', [Validators.required]),
      originatorOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      originatorOfficeOperationalStreet: new FormControl('', [Validators.required]),
      originatorOfficeOperationalCity: new FormControl('', [Validators.required]),
      originatorOfficeOperationalProvince: new FormControl('', [Validators.required]),
      originatorAdminReferentName: new FormControl('', [Validators.required]),
      originatorAdminReferentSurname: new FormControl('', [Validators.required]),
      originatorAdminReferentEmail: new FormControl('', [Validators.required]),
      originatorAdminReferentTelephone: new FormControl('', [Validators.required]),
      originatorSolicitorName: new FormControl('', [Validators.required]),
      originatorSolicitorSurname: new FormControl('', [Validators.required]),
      originatorSolicitorBirthplace: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      nazioneLeg: new FormControl(''),
      REA_CODE: new FormControl(''),
      legalAddressCAP: new FormControl('')
    });
    // fix in prod
    if (this.originatorMod.originatorSolicitorName == undefined) {
      this.originatorMod.originatorSolicitorName = '';
    }
    if (this.originatorMod.legalAddressCAP == undefined) {
      this.originatorMod.legalAddressCAP = '';
    }

    if (this.originatorMod.nazioneLeg == undefined) {
      this.originatorMod.nazioneLeg = '';
    }
    if (this.originatorMod.REA_CODE == undefined) {
      this.originatorMod.REA_CODE = '';
    }
    if (this.originatorMod.originatorMoreScore == undefined) {
      this.originatorMod.originatorMoreScore = '';
    }
    if (this.originatorMod.originatorDefault == undefined) {
      this.originatorMod.originatorDefault = '';
    }
    if (this.originatorMod.lastTigranDate == undefined) {
      this.originatorMod.lastTigranDate = '';
    }
    if (this.originatorMod.originatorSolicitorSurname == undefined) {
      this.originatorMod.originatorSolicitorSurname = '';
    }
    if (this.originatorMod.originatorSolicitorBirthplace == undefined) {
      this.originatorMod.originatorSolicitorBirthplace = '';
    }
    if (this.originatorMod.originatorSolicitorBirthdate == undefined) {
      this.originatorMod.originatorSolicitorBirthdate = '';
    }
    if (this.originatorMod.telefono == undefined) {
      this.originatorMod.phone_number = '';
    } else {
      this.originatorMod.phone_number = this.originatorMod.telefono;
    }

    this.profileOriModForm.setValue({
      name: this.originatorMod.name,
      email: this.originatorMod.email,
      phone_number: this.originatorMod.phone_number,
      originatorPec: this.originatorMod.originatorPec,
      originatorPIva: this.originatorMod.originatorPIva,
      originatorMoreScore: this.originatorMod.originatorMoreScore,
      originatorDefault: this.originatorMod.originatorDefault,
      lastTigranDate: this.originatorMod.lastTigranDate,
      originatorWebSite: this.originatorMod.originatorWebSite,
      originatorCreditsCheck: this.originatorMod.originatorCreditsCheck,
      originatorProducts: {
        originatorFintech: this.originatorMod.originatorProducts[0],
        originatorSupply: this.originatorMod.originatorProducts[1],
        originatorBlue: this.originatorMod.originatorProducts[2]
      },
      originatorOfficeRegisteredStreet: this.originatorMod.originatorOfficeRegisteredStreet,
      originatorOfficeRegisteredCity: this.originatorMod.originatorOfficeRegisteredCity,
      originatorOfficeRegisteredProvince: this.originatorMod.originatorOfficeRegisteredProvince,
      originatorOfficeOperationalStreet: this.originatorMod.originatorOfficeOperationalStreet,
      originatorOfficeOperationalCity: this.originatorMod.originatorOfficeOperationalCity,
      originatorOfficeOperationalProvince: this.originatorMod.originatorOfficeOperationalProvince,
      originatorAdminReferentName: this.originatorMod.originatorAdminReferentName,
      originatorAdminReferentSurname: this.originatorMod.originatorAdminReferentSurname,
      originatorAdminReferentEmail: this.originatorMod.originatorAdminReferentEmail,
      originatorAdminReferentTelephone: this.originatorMod.originatorAdminReferentTelephone,
      originatorSolicitorName: this.originatorMod.originatorSolicitorName,
      originatorSolicitorSurname: this.originatorMod.originatorSolicitorSurname,
      originatorSolicitorBirthplace: this.originatorMod.originatorSolicitorBirthplace,
      originatorSolicitorBirthdate: this.originatorMod.originatorSolicitorBirthdate,
      nazioneLeg: this.originatorMod.nazioneLeg,
      REA_CODE: this.originatorMod.REA_CODE,
      legalAddressCAP: this.originatorMod.legalAddressCAP
    });
  }

  submitOri() {
    this.userToMod.name = this.profileOriModForm.value.name;
    this.userToMod.email = this.profileOriModForm.value.email;
    this.userToMod.originatorPec = this.profileOriModForm.value.originatorPec;
    this.userToMod.originatorPIva = this.profileOriModForm.value.originatorPIva;
    this.userToMod.originatorMoreScore = this.profileOriModForm.value.originatorMoreScore;
    this.userToMod.originatorDefault = this.profileOriModForm.value.originatorDefault;
    this.userToMod.originatorWebSite = this.profileOriModForm.value.originatorWebSite;
    this.userToMod.originatorCreditsCheck = this.profileOriModForm.value.originatorCreditsCheck;
    this.userToMod.legalAddressCAP = this.profileOriModForm.value.legalAddressCAP;

    const products = [];

    products[0] = this.profileOriModForm.value.originatorProducts.originatorFintech;
    products[1] = this.profileOriModForm.value.originatorProducts.originatorSupply;
    products[2] = this.profileOriModForm.value.originatorProducts.originatorBlue;
    this.userToMod.originatorProducts = this.products;
    this.userToMod.originatorOfficeRegisteredStreet = this.profileOriModForm.value.originatorOfficeRegisteredStreet;
    this.userToMod.originatorOfficeRegisteredCity = this.profileOriModForm.value.originatorOfficeRegisteredCity;
    this.userToMod.originatorOfficeRegisteredProvince = this.profileOriModForm.value.originatorOfficeRegisteredProvince;
    this.userToMod.originatorOfficeOperationalStreet = this.profileOriModForm.value.originatorOfficeOperationalStreet;
    this.userToMod.originatorOfficeOperationalCity = this.profileOriModForm.value.originatorOfficeOperationalCity;
    this.userToMod.originatorOfficeOperationalProvince = this.profileOriModForm.value.originatorOfficeOperationalProvince;
    this.userToMod.originatorAdminReferentName = this.profileOriModForm.value.originatorAdminReferentName;
    this.userToMod.originatorAdminReferentSurname = this.profileOriModForm.value.originatorAdminReferentSurname;
    this.userToMod.originatorAdminReferentEmail = this.profileOriModForm.value.originatorAdminReferentEmail;
    this.userToMod.originatorAdminReferentTelephone = this.profileOriModForm.value.originatorAdminReferentTelephone;
    this.userToMod.originatorSolicitorName = this.profileOriModForm.value.originatorSolicitorName;
    this.userToMod.originatorSolicitorSurname = this.profileOriModForm.value.originatorSolicitorSurname;
    this.userToMod.originatorSolicitorBirthplace = this.profileOriModForm.value.originatorSolicitorBirthplace;
    this.userToMod.originatorSolicitorBirthdate = this.profileOriModForm.value.originatorSolicitorBirthdate;
    this.userToMod.telefono = this.profileOriModForm.value.phone_number;
    this.userToMod.nazioneLeg = this.profileOriModForm.value.nazioneLeg;
    this.userToMod.stato = 'ITA';
    this.userToMod.REA_CODE = this.profileOriModForm.value.REA_CODE;
    //  this.userToMod.telefono=this.profileOriModForm.value.phone_number;
    if (this.callTigran) {
      this.userToMod.lastTigranDate = new Date().toUTCString();
    }
    this.callTigran = false;
    if (this.sessione) {
      this.firebase.modUser(this.userToMod);
    } else {
      this.firebase.modUserById(this.userToMod, this.userToMod.id);
    }

    this.activeModal.dismiss();
  }

  createFormModCedente() {
    this.profileAssModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      assignorType: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      assignorPec: new FormControl('', [Validators.required]),
      REA_CODE: new FormControl('', [Validators.required]),
      pIva: new FormControl('', [Validators.required]),
      fiscalCode: new FormControl(''),
      phone_number: new FormControl(''),
      assignorMoreScore: new FormControl(''),
      assignorDefault: new FormControl(''),
      assignorName: new FormControl(''),
      cognome: new FormControl(''),
      lastTigranDate: new FormControl(''),
      refName: new FormControl('', [Validators.required]),
      refMail: new FormControl('', [Validators.required]),
      refPhone: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl(''),
      legalAddressStreet: new FormControl('', [Validators.required]),
      legalAddressCity: new FormControl('', [Validators.required]),
      legalAddressProvince: new FormControl('', [Validators.required]),
      legalAddressCAP: new FormControl(''),
      nazione: new FormControl(''),
      iban: new FormControl(''),
      legalAddressDataNascita: new FormControl('')
    });
    if (this.assignorMod.assignorMoreScore == undefined) {
      this.assignorMod.assignorMoreScore = '';
    }
    if (this.assignorMod.assignorDefault == undefined) {
      this.assignorMod.assignorDefault = '';
    }
    if (this.assignorMod.nazione == undefined) {
      this.assignorMod.nazione = '';
    }
    if (this.assignorMod.lastTigranDate == undefined) {
      this.assignorMod.lastTigranDate = '';
    }
    if (this.assignorMod.assignorName == undefined) {
      this.assignorMod.assignorName = '';
    }
    if (this.assignorMod.surname == undefined) {
      this.assignorMod.surname = '';
    }
    if (this.assignorMod.fiscalCode == undefined) {
      this.assignorMod.fiscalCode = '';
    }
    if (this.assignorMod.REA_CODE == undefined) {
      this.assignorMod.REA_CODE = '';
    }
    if (this.assignorMod.denominazione == undefined) {
      this.assignorMod.denominazione = '';
    }
    if (this.assignorMod.legalAddressStreet == undefined) {
      this.assignorMod.legalAddressStreet = '';
    }
    if (this.assignorMod.legalAddressCity == undefined) {
      this.assignorMod.legalAddressCity = '';
    }
    if (this.assignorMod.legalAddressProvince == undefined) {
      this.assignorMod.legalAddressProvince = '';
    }
    if (this.assignorMod.legalAddressCAP == undefined) {
      this.assignorMod.legalAddressCAP = '';
    }
    if (this.assignorMod.iban == undefined) {
      this.assignorMod.iban = '';
    }
    if (this.assignorMod.legalAddressDataNascita == undefined) {
      this.assignorMod.legalAddressDataNascita = '';
    }

    if (this.assignorMod.telefono == undefined || this.assignorMod.telefono == '') {
      this.assignorMod.phone_number = '';
      this.assignorMod.telefono = '';
    } else {
      this.assignorMod.phone_number = this.assignorMod.telefono;
      this.assignorMod.telefono = this.assignorMod.telefono;
    }

    if (this.assignorMod.originatorSolicitorBirthdate == undefined) {
      this.assignorMod.originatorSolicitorBirthdate = new Date();
    } else {
      this.assignorMod.originatorSolicitorBirthdate = this.assignorMod.originatorSolicitorBirthdate;
    }

    this.profileAssModForm.setValue({
      name: this.assignorMod.denominazione,
      assignorType: this.assignorMod.assignorType,
      email: this.assignorMod.email,
      assignorPec: this.assignorMod.assignorPec,
      REA_CODE: this.assignorMod.REA_CODE,
      pIva: this.assignorMod.pIva,
      fiscalCode: this.assignorMod.fiscalCode,
      assignorMoreScore: this.assignorMod.assignorMoreScore,
      assignorDefault: this.assignorMod.assignorDefault,
      lastTigranDate: this.assignorMod.lastTigranDate,
      refName: this.assignorMod.refName,
      assignorName: this.assignorMod.name,
      cognome: this.assignorMod.surname,
      refMail: this.assignorMod.refMail,
      refPhone: this.assignorMod.refPhone,
      phone_number: this.assignorMod.telefono,
      originatorSolicitorBirthdate: this.assignorMod.originatorSolicitorBirthdate,
      legalAddressStreet: this.assignorMod.legalAddressStreet,
      legalAddressCity: this.assignorMod.legalAddressCity,
      legalAddressProvince: this.assignorMod.legalAddressProvince,
      legalAddressCAP: this.assignorMod.legalAddressCAP,
      nazione: this.assignorMod.nazione,
      iban: this.assignorMod.iban,
      legalAddressDataNascita: this.assignorMod.legalAddressDataNascita
    });
  }

  submitCed() {
    this.userToMod.name = this.profileAssModForm.value.assignorName;
    this.userToMod.denominazione = this.profileAssModForm.value.name;
    this.userToMod.assignorType = this.profileAssModForm.value.assignorType;
    this.userToMod.assignorPec = this.profileAssModForm.value.assignorPec;
    this.userToMod.REA_CODE = this.profileAssModForm.value.REA_CODE;
    this.userToMod.fiscalCode = this.profileAssModForm.value.fiscalCode;
    this.userToMod.pIva = this.profileAssModForm.value.pIva;
    this.userToMod.assignorMoreScore = this.profileAssModForm.value.assignorMoreScore;
    this.userToMod.assignorDefault = this.profileAssModForm.value.assignorDefault;
    this.userToMod.refName = this.profileAssModForm.value.refName;
    this.userToMod.refMail = this.profileAssModForm.value.refMail;
    this.userToMod.refPhone = this.profileAssModForm.value.refPhone;
    this.userToMod.telefono = this.profileAssModForm.value.phone_number;
    this.userToMod.phone_number = this.profileAssModForm.value.phone_number;
    this.userToMod.originatorSolicitorBirthdate = this.profileAssModForm.value.originatorSolicitorBirthdate;
    this.userToMod.surname = this.profileAssModForm.value.cognome;
    this.userToMod.assignorName = this.profileAssModForm.value.assignorName;
    this.userToMod.nazione = this.profileAssModForm.value.nazione;
    this.userToMod.legalAddressStreet = this.profileAssModForm.value.legalAddressStreet;
    this.userToMod.legalAddressCity = this.profileAssModForm.value.legalAddressCity;
    this.userToMod.legalAddressCAP = this.profileAssModForm.value.legalAddressCAP;
    this.userToMod.legalAddressProvince = this.profileAssModForm.value.legalAddressProvince;
    this.userToMod.iban = this.profileAssModForm.value.iban;
    this.userToMod.legalAddressDataNascita = this.profileAssModForm.value.legalAddressDataNascita;
    if (this.callTigran) {
      this.userToMod.lastTigranDate = new Date().toUTCString();
    }
    this.callTigran = false;
    if (this.sessione) {
      this.firebase.modUser(this.userToMod);
    } else {
      this.firebase.modUserById(this.userToMod, this.userToMod.id);
    }

    this.activeModal.dismiss();
  }

  // Form Partner
  createFormModPartner() {
    this.profilePartModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      partnerPIva: new FormControl('', [Validators.required]),
      partnerMoreScore: new FormControl(''),
      partnerDefault: new FormControl(''),
      lastTigranDate: new FormControl(''),
      partnerOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      partnerOfficeRegisteredCity: new FormControl('', [Validators.required]),
      partnerOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      partnerWebSite: new FormControl('', [Validators.required]),
      partnerPec: new FormControl('', [Validators.required])
    });
    if (this.partnerMod.partnerMoreScore == undefined) {
      this.partnerMod.partnerMoreScore = '';
    }
    if (this.partnerMod.partnerDefault == undefined) {
      this.partnerMod.partnerDefault = '';
    }
    if (this.partnerMod.lastTigranDate == undefined) {
      this.partnerMod.lastTigranDate = '';
    }
    this.profilePartModForm.setValue({
      name: this.partnerMod.name,
      email: this.partnerMod.email,
      partnerPIva: this.partnerMod.partnerPIva,
      partnerMoreScore: this.partnerMod.partnerMoreScore,
      partnerDefault: this.partnerMod.partnerDefault,
      lastTigranDate: this.partnerMod.lastTigranDate,
      partnerOfficeRegisteredStreet: this.partnerMod.partnerOfficeRegisteredStreet,
      partnerOfficeRegisteredCity: this.partnerMod.partnerOfficeRegisteredCity,
      partnerOfficeRegisteredProvince: this.partnerMod.partnerOfficeRegisteredProvince,
      partnerWebSite: this.partnerMod.partnerWebSite,
      partnerPec: this.partnerMod.partnerPec
    });
  }

  submitPartner() {
    this.userToMod.name = this.profilePartModForm.value.name;
    this.userToMod.email = this.profilePartModForm.value.email;
    this.userToMod.partnerPIva = this.profilePartModForm.value.partnerPIva;
    this.userToMod.partnerMoreScore = this.profilePartModForm.value.partnerMoreScore;
    this.userToMod.partnerDefault = this.profilePartModForm.value.partnerDefault;
    this.userToMod.partnerOfficeRegisteredStreet = this.profilePartModForm.value.partnerOfficeRegisteredStreet;
    this.userToMod.partnerOfficeRegisteredCity = this.profilePartModForm.value.partnerOfficeRegisteredCity;
    this.userToMod.partnerOfficeRegisteredProvince = this.profilePartModForm.value.partnerOfficeRegisteredProvince;
    this.userToMod.partnerWebSite = this.profilePartModForm.value.partnerWebSite;
    this.userToMod.partnerPec = this.profilePartModForm.value.partnerPec;
    //this.userToMod.telefono=this.profilePartModForm.value.phone_number;
    if (this.callTigran) {
      this.userToMod.lastTigranDate = new Date().toUTCString();
    }
    this.callTigran = false;
    if (this.sessione) {
      this.firebase.modUser(this.userToMod);
    } else {
      this.firebase.modUserById(this.userToMod, this.userToMod.id);
    }
    this.activeModal.dismiss();
  }

  // Form Investor
  createFormModInvestor() {
    this.profileInvestorModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      investorPIva: new FormControl('', [Validators.required]),
      investorMoreScore: new FormControl(''),
      investorDefault: new FormControl(''),
      lastTigranDate: new FormControl(''),
      fiscalCode: new FormControl(''),
      investorOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      investorOfficeRegisteredCity: new FormControl('', [Validators.required]),
      investorOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      investorWebSite: new FormControl('', [Validators.required]),
      investorPec: new FormControl('', [Validators.required]),
      originatorSolicitorName: new FormControl('', [Validators.required]),
      originatorSolicitorSurname: new FormControl('', [Validators.required]),
      originatorSolicitorBirthplace: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      nazione: new FormControl(''),
      REA_CODE: new FormControl(''),
      legalAddressCAP: new FormControl('')
    });
    if (this.investorMod.legalAddressCAP == undefined) {
      this.investorMod.legalAddressCAP = '';
    }
    if (this.investorMod.investorMoreScore == undefined) {
      this.investorMod.investorMoreScore = '';
    }
    if (this.investorMod.REA_CODE == undefined) {
      this.investorMod.REA_CODE = '';
    }
    if (this.investorMod.nazione == undefined) {
      this.investorMod.nazione = '';
    }
    if (this.investorMod.investorDefault == undefined) {
      this.investorMod.investorDefault = '';
    }
    if (this.investorMod.lastTigranDate == undefined) {
      this.investorMod.lastTigranDate = '';
    }
    if (this.investorMod.phone_number == undefined) {
      this.investorMod.phone_number = '';
    }

    if (this.investorMod.originatorSolicitorBirthdate == undefined) {
      this.investorMod.originatorSolicitorBirthdate = new Date();
    }
    if (this.investorMod.originatorSolicitorName == undefined) {
      this.investorMod.originatorSolicitorName = '';
    }
    if (this.investorMod.originatorSolicitorSurname == undefined) {
      this.investorMod.originatorSolicitorSurname = '';
    }
    if (this.investorMod.originatorSolicitorBirthplace == undefined) {
      this.investorMod.originatorSolicitorBirthplace = '';
    }

    this.profileInvestorModForm.setValue({
      name: this.investorMod.name,
      email: this.investorMod.email,
      phone_number: this.investorMod.telefono,
      investorPIva: this.investorMod.investorPIva,
      investorMoreScore: this.investorMod.investorMoreScore,
      investorDefault: this.investorMod.investorDefault,
      lastTigranDate: this.investorMod.lastTigranDate,
      investorOfficeRegisteredStreet: this.investorMod.investorOfficeRegisteredStreet,
      investorOfficeRegisteredCity: this.investorMod.investorOfficeRegisteredCity,
      investorOfficeRegisteredProvince: this.investorMod.investorOfficeRegisteredProvince,
      investorWebSite: this.investorMod.investorWebSite,
      investorPec: this.investorMod.investorPec,
      originatorSolicitorName: this.investorMod.originatorSolicitorName,
      originatorSolicitorSurname: this.investorMod.originatorSolicitorSurname,
      originatorSolicitorBirthplace: this.investorMod.originatorSolicitorBirthplace,
      originatorSolicitorBirthdate: this.investorMod.originatorSolicitorBirthdate,
      fiscalCode: this.investorMod.fiscalCode,
      nazione: this.investorMod.nazione,
      REA_CODE: this.investorMod.REA_CODE,
      legalAddressCAP: this.investorMod.legalAddressCAP
    });
  }

  submitInvestor() {
    this.userToMod.name = this.profileInvestorModForm.value.name;
    this.userToMod.email = this.profileInvestorModForm.value.email;
    this.userToMod.phone_number = this.profileInvestorModForm.value.phone_number;
    this.userToMod.telefono = this.profileInvestorModForm.value.phone_number;
    this.userToMod.investorPIva = this.profileInvestorModForm.value.investorPIva;
    this.userToMod.investorMoreScore = this.profileInvestorModForm.value.investorMoreScore;
    this.userToMod.investorDefault = this.profileInvestorModForm.value.investorDefault;
    this.userToMod.investorOfficeRegisteredStreet = this.profileInvestorModForm.value.investorOfficeRegisteredStreet;
    this.userToMod.investorOfficeRegisteredCity = this.profileInvestorModForm.value.investorOfficeRegisteredCity;
    this.userToMod.investorOfficeRegisteredProvince = this.profileInvestorModForm.value.investorOfficeRegisteredProvince;
    this.userToMod.investorWebSite = this.profileInvestorModForm.value.investorWebSite;
    this.userToMod.investorPec = this.profileInvestorModForm.value.investorPec;
    this.userToMod.originatorSolicitorName = this.profileInvestorModForm.value.originatorSolicitorName;
    this.userToMod.originatorSolicitorSurname = this.profileInvestorModForm.value.originatorSolicitorSurname;
    this.userToMod.originatorSolicitorBirthplace = this.profileInvestorModForm.value.originatorSolicitorBirthplace;
    this.userToMod.originatorSolicitorBirthdate = this.profileInvestorModForm.value.originatorSolicitorBirthdate;
    this.userToMod.fiscalCode = this.profileInvestorModForm.value.fiscalCode;
    this.userToMod.nazione = this.profileInvestorModForm.value.nazione;
    this.userToMod.REA_CODE = this.profileInvestorModForm.value.REA_CODE;
    this.userToMod.legalAddressCAP = this.profileInvestorModForm.value.legalAddressCAP;
    if (this.callTigran) {
      this.userToMod.lastTigranDate = new Date().toUTCString();
    }
    this.callTigran = false;
    if (this.sessione) {
      this.firebase.modUser(this.userToMod);
    } else {
      this.firebase.modUserById(this.userToMod, this.userToMod.id);
    }
    this.activeModal.dismiss();
  }

  createFormModServicer() {
    this.profileServicerModForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required])
    });
    this.profileServicerModForm.setValue({
      firstName: this.servicerMod.name,
      lastName: this.servicerMod.lastName,
      email: this.servicerMod.email
    });
  }

  submitServicer() {
    this.userToMod.name = this.profileServicerModForm.value.firstName;
    this.userToMod.lastName = this.profileServicerModForm.value.lastName;
    this.userToMod.email = this.profileServicerModForm.value.email;
    this.userToMod.telefono = this.profileServicerModForm.value.phone_number;
    if (this.sessione) {
      this.firebase.modUser(this.userToMod);
    } else {
      this.firebase.modUserById(this.userToMod, this.userToMod.id);
    }
    this.activeModal.dismiss();
  }

  // get partner per validator
  get investorEmail() {
    return this.profileInvestorModForm.get('email');
  }

  get phone_number() {
    return this.profileInvestorModForm.get('phone_number');
  }

  get investorPassword() {
    return this.profileInvestorModForm.get('password');
  }

  get investorName() {
    return this.profileInvestorModForm.get('name');
  }

  get investorPIva() {
    return this.profileInvestorModForm.get('investorPIva');
  }

  // get partner per validator
  get partnerEmail() {
    return this.profilePartModForm.get('email');
  }

  get partnerPassword() {
    return this.profilePartModForm.get('password');
  }

  get partnerName() {
    return this.profilePartModForm.get('name');
  }

  get partnerPIva() {
    return this.profilePartModForm.get('partnerPIva');
  }

  // get cedente per validator

  get assignorEmail() {
    return this.profileAssModForm.get('email');
  }

  get assignorPassword() {
    return this.profileAssModForm.get('password');
  }

  get assignorUSername() {
    return this.profileAssModForm.get('name');
  }

  get assignorType() {
    return this.profileAssModForm.get('assignorType');
  }

  get assignorFiscalCode() {
    return this.profileAssModForm.get('fiscalCode');
  }

  // get originator per validator

  get originatorEmail() {
    return this.profileOriModForm.get('email');
  }

  get originatorPassword() {
    return this.profileOriModForm.get('password');
  }

  get originatorName() {
    return this.profileOriModForm.get('name');
  }

  get originatorPIVA() {
    return this.profileOriModForm.get('originatorPIva');
  }

  get originatorProducts() {
    return this.profileOriModForm.get('originatorProducts');
  }

  isCedente() {
    return this.userToMod.roles[0] == Utilities.userType[3];
  }

  isOriginator() {
    return this.userToMod.roles[0] == Utilities.userType[2];
  }

  get servicerName() {
    return this.profileServicerModForm.get('firstName');
  }

  get servicerLastName() {
    return this.profileServicerModForm.get('lastName');
  }

  get assignorName() {
    return this.profileServicerModForm.get('assignorName');
  }

  get servicerEmail() {
    return this.profileServicerModForm.get('email');
  }

  showConfirmTigranModal(pIva: string, userType: string) {
    this.tigranLoading = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text = 'Sei sicuro di voler effettuare una chiamata alle API di Tigran?';
    modalRef.componentInstance.pIva = pIva;
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      this.tigranLoading = data;
    });
    modalRef.componentInstance.moreScore.subscribe((data: string) => {
      this.assignTigranValues(data[0], data[1], userType);
      this.callTigran = true;
    });
  }

  assignTigranValues(moreScore: string, probDefault: string, userType: string) {
    switch (userType) {
      case 'originator': {
        this.profileOriModForm.controls.originatorMoreScore.setValue(moreScore);
        this.profileOriModForm.controls.originatorDefault.setValue(probDefault);
        this.profileOriModForm.controls.lastTigranDate.setValue(new Date().toUTCString());
        break;
      }
      case 'investor': {
        this.profileInvestorModForm.controls.investorMoreScore.setValue(moreScore);
        this.profileInvestorModForm.controls.investorDefault.setValue(probDefault);
        this.profileInvestorModForm.controls.lastTigranDate.setValue(new Date().toUTCString());
        break;
      }
      case 'assignor': {
        this.profileAssModForm.controls.assignorMoreScore.setValue(moreScore);
        this.profileAssModForm.controls.assignorDefault.setValue(probDefault);
        this.profileAssModForm.controls.lastTigranDate.setValue(new Date().toUTCString());
        break;
      }
      case 'partner': {
        this.profilePartModForm.controls.partnerMoreScore.setValue(moreScore);
        this.profilePartModForm.controls.partnerDefault.setValue(probDefault);
        this.profilePartModForm.controls.lastTigranDatenew.setValue(new Date().toUTCString());
      }
    }
  }
}
