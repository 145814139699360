import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyComponent } from './company.component';
import { CompanyRoutingModule } from './company-routing.module';
import { HomeModule } from '@app/home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PendingInvestorComponent } from './pending-investor/pending-investor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [CompanyComponent, PendingInvestorComponent],
  imports: [
    HomeModule,
    CommonModule,
    ReactiveFormsModule,
    CompanyRoutingModule,
    NgbModule,
    ClipboardModule,
    SharedModule,
    FormsModule
  ]
})
export class CompanyModule {}
