import { InvestitorePercentuale } from './InvestitorePercentuale';
import { Investor } from './Investor.model';

export class Invoice {
  static tableName = 'Invoices';
  id: string;
  uid: string;
  cedenteName: string; // nome azienda //
  numFattura: string; //
  dataFattura: string; //
  dataScadenza: string;
  dataCaricaBonifico: string; // quando viene caricato il bonifico del pagamento
  maturity: string;
  importoFattura: number; //
  importoDaScontare: number; //
  scontoProposto: number; //
  rendimentoProposto: number; //
  controproposta: number; //
  scontoAccettato: number; // ??????
  rendimentoAccettato: number; //
  status: string; //
  partner: string;
  walletId: string;
  walletName: string;
  usuryRespected: boolean;
  investor: string;
  dataCaricaContratto: string; // quando viene caricato il contratto del pagamento
  listaInvestitori: string[];
  listaInvestitoriPagamento: string[];
  listaInvestitoriDaPagare: string[];
  listaProposteInvestimento: string[];
  modified: boolean; // campo ausiliario da cancellare quando si va su firebase
  otp: string[];

  // @hydra 2022-01-14 -- @author - Gaetano Bitonte - https://hydrasolutions.atlassian.net/browse/FXINPROD-87
  status_index: number;

  static cleanForFirebase(invoice: Invoice) {
    delete invoice.modified;
  }
  constructor() {}
}
