import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { PendingService } from '@app/pending.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  constructor(public firebaseService: FirebaseTranchedService, public pending: PendingService) {}

  ngOnInit() {
    this.calculatePendingRequests();
  }

  calculatePendingRequests(): void {
    this.pending.setPendingFornitore(0);
    this.firebaseService.getInvoicesNotApprovedFornitore().subscribe(docs => {
      var finalList = [];
      //console.log(docs);
      for (var invoice of docs) {
        //console.log(wallet.id);
        if (invoice.uid == this.firebaseService.loggedUser.id) {
          //console.log(wallet);
          finalList.push(invoice);
        }
      }

      this.pending.setPendingFornitore(finalList.length);
    });
  }
}
