import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import { environment } from '@env/environment.prod';

import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-new-servicer',
  templateUrl: './new-servicer.component.html',
  styleUrls: ['./new-servicer.component.scss']
})
export class NewServicerComponent implements OnInit {
  products: string[] = Utilities.productNames;
  servicerCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router
  ) {
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page new-servicer');
  }

  ngOnInit() {}

  submit() {
    this.isSubmitted = true;
    if (!this.servicerCreationForm.valid && !this.verifyPass()) {
      this.servicerCreationForm.get('firstName').markAsTouched();
      this.servicerCreationForm.get('lastName').markAsTouched();
      this.servicerCreationForm.get('email').markAsTouched();
      this.servicerCreationForm.get('password').markAsTouched();
    } else {
      let objectToSave = this.firebaseService.createServicer(this.servicerCreationForm);
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          this.router.navigate(['/home//users-credential']);
        })
        .catch(error => {
          // Handle Errors here.
          let errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.servicerCreationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      tel: new FormControl(''),
      indirizzo: new FormControl(''),
      data_assunzione: new FormControl(''),
      data_contratto: new FormControl('')
    });
  }

  get email() {
    return this.servicerCreationForm.get('email');
  }

  get password() {
    return this.servicerCreationForm.get('password');
  }

  get firstName() {
    return this.servicerCreationForm.get('firstName');
  }

  get lastName() {
    return this.servicerCreationForm.get('lastName');
  }

  verifyPass() {
    console.log(this.servicerCreationForm.get('password').value);
    console.log(this.servicerCreationForm.get('email').value);

    if (
      this.servicerCreationForm.get('password').value == this.servicerCreationForm.get('email').value ||
      this.servicerCreationForm.get('password').value == this.servicerCreationForm.get('firstName').value ||
      this.servicerCreationForm.get('password').value == this.servicerCreationForm.get('lastName').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
