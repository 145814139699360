import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '@app/models/User.model';
import { ModalProfileComponent } from '@app/profile/modal-profile/modal-profile.component';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import { ModalProfileInvestorViewComponent } from '@app/profile/modal-profile-investor-view/modal-profile-investor-view.component';
import { ConfirmationModalComponent } from '@app/tigran/confirmation-modal/confirmation-modal.component';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { ShowMessageService } from '@app/core/show-message.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-users-credential',
  templateUrl: './users-credential.component.html',
  styleUrls: ['./users-credential.component.scss']
})
export class UsersCredentialComponent implements OnInit, OnDestroy {
  [x: string]: any;
  ordNome: boolean;
  ordEmail: boolean;
  public usersList: Observable<any[]>;
  public tipologia: string;
  public roles: string;
  caricaUsers: any[] = [];
  usersItems: any[];
  sub: Subscription;
  investorUrl = 'investor';
  profileUpdate = 'profileUpdate';
  walletList = 'walletList';

  constructor(
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private afAuth: AngularFireAuth,
    private router: Router,
    private lemonwayServicer: LemonwayService,
    public showService: ShowMessageService,
    private http: HttpClient
  ) {
    this.tipologia = 'all';
    this.roles = 'all';
    this.ordNome = false;
    this.ordEmail = false;
    this.firebase.createPageLog('User Visit Page users-credential');
  }

  ngOnInit() {
    const investorRoute = this.router.url.includes(this.investorUrl);
    this.usersList = investorRoute ? this.firebase.getUsersByRole(Utilities.userType[2]) : this.firebase.getUsers();
    this.sub = this.usersList.subscribe(data => {
      this.usersItems = data;

      data.forEach(us => {
        if (us.lemonwayAccount == true && us.roles[0] == 'investor' && !us.isPrivato) {
          this.lemonwayServicer.infoStatus(us.investorPIva).subscribe(data2 => {
            if (data2.account) {
              //console.log(data2.account);
              if (data2.account.status == 6) {
                us.statoLemonway = Utilities.statoLemon[2];
                this.firebase.modUser(us);
              } else {
                us.statoLemonway = Utilities.statoLemon[3];
                this.firebase.modUser(us);
              }
            }
          });
        }
        if (us.lemonwayAccount == true && us.roles[0] == 'company' && !us.isPrivato) {
          this.lemonwayServicer.infoStatus(us.pIva).subscribe(data2 => {
            if (data2.account) {
              //console.log(data2.account);
              if (data2.account.status == 6) {
                us.statoLemonway = Utilities.statoLemon[2];
                this.firebase.modUser(us);
              } else {
                us.statoLemonway = Utilities.statoLemon[3];
                this.firebase.modUser(us);
              }
            }
          });
        }
        if (us.lemonwayAccount == true && us.roles[0] == 'investor' && us.isPrivato) {
          this.lemonwayServicer.infoStatus(us.fiscalCode).subscribe(data2 => {
            if (data2.account) {
              //console.log(data2.account);
              if (data2.account.status == 6) {
                us.statoLemonway = Utilities.statoLemon[2];
                this.firebase.modUser(us);
              } else {
                us.statoLemonway = Utilities.statoLemon[3];
                this.firebase.modUser(us);
              }
            }
          });
        }
        if (us.lemonwayAccount == true && us.roles[0] == 'company' && us.isPrivato) {
          this.lemonwayServicer.infoStatus(us.fiscalCode).subscribe(data2 => {
            if (data2.account) {
              //console.log(data2.account);
              if (data2.account.status == 6) {
                us.statoLemonway = Utilities.statoLemon[2];
                this.firebase.modUser(us);
              } else {
                us.statoLemonway = Utilities.statoLemon[3];
                this.firebase.modUser(us);
              }
            }
          });
        }
        if (us.lemonwayAccount == true && us.roles[0] == 'originator') {
          this.lemonwayServicer.infoStatus(us.originatorPIva).subscribe(data2 => {
            if (data2.account) {
              // console.log(data2.account);
              if (data2.account.status == 6) {
                us.statoLemonway = Utilities.statoLemon[2];
                this.firebase.modUser(us);
              } else {
                us.statoLemonway = Utilities.statoLemon[3];
                this.firebase.modUser(us);
              }
            }
          });
        }
      });
      investorRoute ? (this.roles = Utilities.userType[5]) : (this.roles = 'all');
      this.loadUser();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  resetPassword(mail: string) {
    // TODO con questo metodo possiamo inviare la mail di verifica email alla registration del profilo
    this.afAuth.auth.sendPasswordResetEmail(mail);
    alert('Mail di reset password inviata con successo.');
  }

  loadUser() {
    this.caricaUsers = [];
    if (this.tipologia == 'all') {
      for (const user of this.usersItems) {
        // console.log(user);
        try {
          if (!user.roles.includes('admin')) {
            this.caricaUsers.push(user);
          }
        } catch (e) {
          // console.log(user);
        }
      }
    } else if (this.tipologia == 'company-private') {
      const newType = 'company';
      for (const user of this.usersItems) {
        if (
          user.roles.includes(newType) &&
          !user.roles.includes('admin') &&
          user.isPrivato &&
          user.fiscalCode !== null
        ) {
          this.caricaUsers.push(user);
        }
      }
    } else {
      for (const user of this.usersItems) {
        try {
          if (user.roles.includes(this.tipologia) && !user.roles.includes('admin')) {
            this.caricaUsers.push(user);
          }
        } catch (e) {
          // console.log(e);
        }
      }
    }
  }

  ordinaCliente() {
    //console.log(this.ordNome);
    if (this.ordNome == false) {
      this.caricaUsers = _.orderBy(this.caricaUsers, [user => user.name.toLowerCase()], ['asc']);
    }
    if (this.ordNome == true) {
      this.caricaUsers = _.orderBy(this.caricaUsers, [user => user.name.toLowerCase()], ['desc']);
    }
    this.ordNome = !this.ordNome;
  }
  ordinaEmail() {
    //console.log(this.ordEmail);
    if (this.ordEmail == false) {
      this.caricaUsers = _.orderBy(this.caricaUsers, [user => user.email.toLowerCase()], ['asc']);
    }
    if (this.ordEmail == true) {
      this.caricaUsers = _.orderBy(this.caricaUsers, [user => user.email.toLowerCase()], ['desc']);
    }
    this.ordEmail = !this.ordEmail;
  }

  controllo(us: any) {
    return us.statoLemonway == Utilities.statoLemon[2];
  }

  showModal(userIn: User, type: string) {
    switch (type) {
      case this.profileUpdate: {
        const modalRef = this.modalService.open(ModalProfileComponent, {
          size: 'lg',
          centered: true,
          scrollable: true
        });
        modalRef.componentInstance.userInput = userIn;
        break;
      }
      case this.walletList: {
        const modalRef = this.modalService.open(ModalProfileInvestorViewComponent, {
          size: 'lg',
          centered: true,
          scrollable: true
        });
        modalRef.componentInstance.userInput = userIn;
        break;
      }
    }
  }

  elimina(userIn: User) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text =
      'Sei sicuro di voler eliminare l utente, non potrà più accedere ma le sue informazioni resteranno sulla piattaforma';
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      if (data) {
        this.firebase.disattivaUser(userIn);
      }
    });
  }
  riattiva(userIn: User) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text =
      'Sei sicuro di voler riattivare l utente esso potra accedere normalmente alla piattaforma';
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      if (data) {
        this.firebase.attivaUser(userIn);
      }
    });
  }

  attivaLemon(userIn: any) {
    let pipe = new DatePipe('en-US');
    if (!userIn.telefono) {
      userIn.telefono = '0984998877';
    }

    if (userIn.roles == 'originator') {
      // console.log('originator');
      this.lemonwayServicer
        .createaNewLegalAccount(
          userIn.originatorPIva,
          userIn.originatorSolicitorName,
          userIn.originatorSolicitorSurname,
          userIn.stato,
          1,
          userIn.email,
          userIn.originatorOfficeRegisteredStreet,
          userIn.legalAddressCAP,
          userIn.originatorOfficeRegisteredCity,
          pipe.transform(userIn.originatorSolicitorBirthdate, 'y/MM/dd'),
          userIn.originatorSolicitorBirthplace,
          'M',
          userIn.telefono,
          userIn.telefono,
          false,
          false,
          userIn.name,
          userIn.name,
          true,
          userIn.REA_CODE
        )
        .subscribe(data => {
          console.log('DATI=>', data);
          if (data.legalAccount) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          }
          if (data.code == 200) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else if (data.error.code == 152) {
            // utente esistente
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else {
            if (data.code == 234) {
              // formato telefono non valido
              alert('Formato telefono non valido');
            }
            try {
              if (data.error.code == 152) {
                // utente esistente
                userIn.lemonwayAccount = true;
                userIn.statoLemonway = Utilities.statoLemon[3];
                this.showService.showListaUtentiSuccess = true;
                window.scrollTo(0, 0);
                this.firebase.modUser(userIn);
              }
              this.showService.showListaUtenti = true;
              window.scrollTo(0, 0);
            } catch (e) {
              //console.log('exception');
            }
          }
          try {
            alert(data.error.message);
          } catch (e) {}
        });
    }
    if (userIn.roles == 'investor' && !userIn.isPrivato) {
      //console.log('investor piva');
      // @ts-ignore
      this.lemonwayServicer
        .createaNewLegalAccount(
          userIn.investorPIva,
          userIn.originatorSolicitorName,
          userIn.originatorSolicitorSurname,
          'ITA',
          1,
          userIn.email,
          userIn.investorOfficeRegisteredStreet,
          userIn.legalAddressCAP,
          userIn.investorOfficeRegisteredCity,
          pipe.transform(userIn.originatorSolicitorBirthdate, 'y/MM/dd'),
          userIn.originatorSolicitorBirthplace,
          'M',
          userIn.telefono,
          userIn.telefono,
          false,
          false,
          userIn.name,
          userIn.name,
          false,
          userIn.REA_CODE
        )
        .subscribe(data => {
          //console.log(data);
          if (data.legalAccount) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          }
          if (data.code == 200) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else {
            if (data.code == 234) {
              // formato telefono non valido
              alert('Formato telefono non valido');
            }
            try {
              if (data.error.code == 152) {
                // utente esistente
                userIn.lemonwayAccount = true;
                userIn.statoLemonway = Utilities.statoLemon[3];
                this.showService.showListaUtentiSuccess = true;
                window.scrollTo(0, 0);
                this.firebase.modUser(userIn);
              }
              this.showService.showListaUtenti = true;
              window.scrollTo(0, 0);
            } catch (e) {
              //('exception');
            }
          }
          try {
            alert(data.error.message);
          } catch (e) {}
        });
    }
    if (userIn.roles == 'investor' && userIn.isPrivato) {
      if (userIn.originatorSolicitorName == '' || userIn.originatorSolicitorSurname == '') {
        alert('vanno inseriti un name e surname');
      }
      //console.log('investor privato');
      this.lemonwayServicer
        .createaNewIndividualAccount(
          userIn.fiscalCode,
          userIn.originatorSolicitorName,
          userIn.originatorSolicitorSurname,
          'ITA',
          1,
          userIn.email,
          userIn.investorOfficeRegisteredStreet,
          userIn.legalAddressCAP,
          userIn.investorOfficeRegisteredCity,
          pipe.transform(userIn.originatorSolicitorBirthdate, 'y/MM/dd'),
          userIn.originatorSolicitorBirthplace,
          'M',
          userIn.telefono,
          userIn.telefono,
          false,
          false
        )
        .subscribe(data => {
          // console.log(data);
          if (data.legalAccount) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          }
          if (data.code == 200) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else if (data.error.code == 152) {
            // utente esistente
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else {
            if (data.code == 234) {
              // formato telefono non valido
              alert('Formato telefono non valido');
            }
            try {
              if (data.error.code == 152) {
                // utente esistente
                userIn.lemonwayAccount = true;
                userIn.statoLemonway = Utilities.statoLemon[3];
                this.showService.showListaUtentiSuccess = true;
                window.scrollTo(0, 0);
                this.firebase.modUser(userIn);
              }
              this.showService.showListaUtenti = true;
              window.scrollTo(0, 0);
            } catch (e) {
              //  console.log('exception');
            }
          }
          try {
            alert(data.error.message);
          } catch (e) {}
        });
    }
    if (userIn.roles == 'company' && !userIn.isPrivato) {
      // console.log('company piva');
      this.lemonwayServicer
        .createaNewLegalAccount(
          userIn.pIva,
          userIn.refName,
          userIn.surname,
          'ITA',
          2,
          userIn.email,
          userIn.legalAddressStreet,
          userIn.legalAddressCAP,
          userIn.legalAddressCity,
          pipe.transform(userIn.legalAddressDataNascita, 'y/MM/dd'),
          userIn.originatorSolicitorBirthplace,
          'M',
          userIn.telefono,
          userIn.telefono,
          false,
          false,
          userIn.name,
          userIn.name,
          false,
          userIn.REA_CODE
        )
        .subscribe(data => {
          //console.log(data);
          if (data.legalAccount) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          }
          try {
            if (data.code == 200) {
              userIn.lemonwayAccount = true;
              userIn.statoLemonway = Utilities.statoLemon[3];
              this.showService.showListaUtentiSuccess = true;
              window.scrollTo(0, 0);
              this.firebase.modUser(userIn);
            } else if (data.error.code == 152) {
              // utente esistente
              userIn.lemonwayAccount = true;
              userIn.statoLemonway = Utilities.statoLemon[3];
              this.showService.showListaUtentiSuccess = true;
              window.scrollTo(0, 0);
              this.firebase.modUser(userIn);
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  userIn.lemonwayAccount = true;
                  userIn.statoLemonway = Utilities.statoLemon[3];
                  this.showService.showListaUtentiSuccess = true;
                  window.scrollTo(0, 0);
                  this.firebase.modUser(userIn);
                }
                this.showService.showListaUtenti = true;
                window.scrollTo(0, 0);
              } catch (e) {
                //  console.log('exception');
              }
            }
          } catch (e) {
            console.log('DATA=>', data);
            console.log('Error=>', e);
          }
          try {
            alert(data.error.message);
          } catch (e) {}
        });
    }
    if (userIn.roles == 'company' && userIn.isPrivato) {
      //console.log('company piva');
      this.lemonwayServicer
        .createaNewIndividualAccount(
          userIn.fiscalCode,
          userIn.name,
          userIn.surname,
          'ITA',
          2,
          userIn.email,
          userIn.legalAddressStreet,
          userIn.legalAddressCAP,
          userIn.legalAddressCity,
          pipe.transform(userIn.legalAddressDataNascita, 'y/MM/dd'),
          userIn.originatorSolicitorBirthplace,
          'M',
          userIn.telefono,
          userIn.telefono,
          false,
          false
        )
        .subscribe(data => {
          //console.log(data);
          if (data.legalAccount) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          }
          if (data.code == 200) {
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else if (data.error.code == 152) {
            // utente esistente
            userIn.lemonwayAccount = true;
            userIn.statoLemonway = Utilities.statoLemon[3];
            this.showService.showListaUtentiSuccess = true;
            window.scrollTo(0, 0);
            this.firebase.modUser(userIn);
          } else {
            if (data.code == 234) {
              // formato telefono non valido
              alert('Formato telefono non valido');
            }
            try {
              if (data.error.code == 152) {
                // utente esistente
                userIn.lemonwayAccount = true;
                userIn.statoLemonway = Utilities.statoLemon[3];
                this.showService.showListaUtentiSuccess = true;
                window.scrollTo(0, 0);
                this.firebase.modUser(userIn);
              }
              this.showService.showListaUtenti = true;
              window.scrollTo(0, 0);
            } catch (e) {
              // console.log('exception');
            }
          }
          try {
            alert(data.error.message);
          } catch (e) {}
        });
    }
    //console.log(userIn)
  }

  testAPI() {
    const urlcreateaNewLegalAccount = Utilities.urlgenerale + '/createaNewLegalAccount?id=03296450780';
    const newLegalAccount =
      '{\n' +
      '    "to":"gaetano.bitonte@gmail.com",\n' +
      '    "firstname":"Gaetano",\n' +
      '    "lastname":"Bitonte",\n' +
      '    "subject":"Registrazione IncassaOra",\n' +
      '    "senderCompany":"FX12 Srl",\n' +
      '    "from":"support@incassaora.it",\n' +
      '    "senderName":"Support of Incassaora",\n' +
      '    "templateName":"registrationSuccessFull"\n' +
      '}';

    this.http.get<any>(urlcreateaNewLegalAccount).subscribe(data => {
      console.log('data=>', data);
    });
  }
}
