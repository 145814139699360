import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { User } from '@app/models/User.model';
import { ModalProfileComponent } from '@app/profile/modal-profile/modal-profile.component';
import { Observable, Subscription } from 'rxjs';
import { PendingService } from '@app/pending.service';
import { Utilities } from '@app/utilities/Utilities';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pending-investor',
  templateUrl: './pending-investor.component.html',
  styleUrls: ['./pending-investor.component.scss']
})
export class PendingInvestorComponent implements OnInit, OnDestroy {
  public tipologia: string;
  public usersList: Observable<any[]>;
  public docsList: Observable<any[]>;
  public pendingUsers: any[] = [];
  public pendingDocs: any[] = [];
  sub: Subscription;

  constructor(
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    public pending: PendingService,
    private router: Router
  ) {
    this.tipologia = 'docs';
    this.firebase.createPageLog('User Visit Page pending Requests');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.docsList = this.firebase.getInvoicesNotApprovedFornitore();
    this.sub = this.docsList.subscribe(data => {
      var finalList = [];
      // console.log(data);
      for (var invoice of data) {
        //console.log(wallet.id);
        if (invoice.uid == this.firebase.loggedUser.id) {
          //console.log(wallet);
          finalList.push(invoice);
        }
      }
      //console.log(finalList);
      this.pendingDocs = finalList;
      this.pending.setPendingFornitore(finalList.length);
    });
  }

  showModal(userIn: User) {
    const modalRef = this.modalService.open(ModalProfileComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.userInput = userIn;
    modalRef.componentInstance.showUpdateButton = false;
  }

  approve(obj: any, type: string) {
    switch (type) {
      case 'doc': {
        this.firebase.approveDocs(obj);
      }
    }
    this.pending.decPenFornitore(1);
  }

  loadPendingRequests(): void {
    //  console.log('qui');
    switch (this.tipologia) {
      case 'docs': {
        this.docsList = this.firebase.getInvoicesNotApprovedFornitore();
        this.sub = this.docsList.subscribe(data => {
          var finalList = [];
          // console.log(data);
          for (var invoice of data) {
            //console.log(wallet.id);
            if (invoice.uid == this.firebase.loggedUser.id) {
              //console.log(wallet);
              finalList.push(invoice);
            }
          }
          //console.log(finalList);
          this.pendingDocs = finalList;
          this.pending.setPendingFornitore(finalList.length);
        });
        break;
      }
    }
  }

  info(data: any) {
    this.router.navigate(['/cedente/wallet-details/' + data.walletId]);
  } //status
}
