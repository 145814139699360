// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
/*
export const environment = {
  production: false,
  hmr: true,
  version: 2 + '-dev',
  serverUrl: 'https://panel.incassaora.it',
  defaultLanguage: 'it-IT',
  supportedLanguages: ['it-IT', 'en-US', 'fr-FR'],
  baseUrl: 'https://panel.incassaora.it/',
  firebase: {
    apiKey: 'AIzaSyCmGZYBcmCJeANBn2C1eTk00jC1PdpdgC0',
    authDomain: 'fx12-incassaora.firebaseapp.com',
    projectId: 'fx12-incassaora',
    storageBucket: 'fx12-incassaora.appspot.com',
    messagingSenderId: '451967623638',
    appId: '1:451967623638:web:e7ad325ae00a6360190477',
    measurementId: 'G-FJWW6LZKVJ'
  },
  saltPass: '$2a$10$R02YXA0PITXFup5js4IBfuQQH6uSMaYRTb5RDJocXO2QcT.ItMZz.',
  tigranUrl: 'https://fx12.oplonrisk.com',
  tigranUsername: 'fx12_api_user',
  tigranPassword: '986;IUGiguihG54678',
  tigranUserMail: 'fx12_api_user@modefinance.com',
  secretFx12_: 'k1gJZU0QYWVysIpzsRx3',
  // laravelServer: 'https://apibackend.incassaora.it/api'
  laravelServer: 'https://incassaoradevback.hydrasolutions.it/api'
  // laravelServer: 'https://incassaora.test/incassaora-laravel/public/api'
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  hmr: false,
  version: '2',
  serverUrl: 'https://panel.incassaora.it',
  defaultLanguage: 'it-IT',
  supportedLanguages: ['it-IT', 'en-US', 'fr-FR'],
  baseUrl: 'https://panel.incassaora.it',
  firebase: {
    apiKey: 'AIzaSyCteMPSoI3TEWAfSpQWtmJSl6XNlE_bFyI',
    authDomain: 'fx-12-prod.firebaseapp.com',
    databaseURL: 'https://incassaora-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'incassaora',
    storageBucket: 'incassaora.appspot.com',
    messagingSenderId: '939382233883',
    appId: '1:939382233883:web:a24047dfd804d40ef891be',
    measurementId: 'G-FJWW6LZKVJ'
  },
  saltPass: '$2a$10$R02YXA0PITXFup5js4IBfuQQH6uSMaYRTb5RDJocXO2QcT.ItMZz.',
  tigranUrl: 'https://fx12.oplonrisk.com',
  tigranUsername: 'fx12_api_user',
  tigranPassword: '986;IUGiguihG54678',
  tigranUserMail: 'fx12_api_user@modefinance.com',
  secretFx12_: 'k1gJZU0QYWVysIpzsRx3',
  laravelServer: 'https://apibackend.incassaora.it/api'
  // laravelServer: 'https://incassaoradevback.hydrasolutions.it/api'
  // laravelServer: 'https://incassaora.test/incassaora-laravel/public/api'
};
