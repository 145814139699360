import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { WalletFormComponent } from './wallet-form/wallet-form.component';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { StatsComponent } from './stats/stats.component';
import { NewBankComponent } from './new-originator/new-bank.component';
import { WalletDetailsComponent } from './wallet-details/wallet-details.component';
import { UsersCredentialComponent } from './users-credential/users-credential.component';
import { Utilities } from '@app/utilities/Utilities';
import { NewPartnerComponent } from './new-partner/new-partner.component';
import { NewInvestorComponent } from './new-investor/new-investor.component';
import { UsuryRatesComponent } from '@app/home/usury-rates/usury-rates.component';
import { CreateUsuryRateComponent } from '@app/home/usury-rates/create-usury-rate/create-usury-rate.component';
import { TigranComponent } from '@app/home/tigran/tigran.component';
import { NewServicerComponent } from '@app/home/new-servicer/new-servicer.component';
import { PendingRequestListComponent } from '@app/home/pending-request-list/pending-request-list.component';
import { LemonwayComponent } from './lemonway/lemonway.component';
import { NewcompanyComponent } from './new-company/newcompany/newcompany.component';
import { ReportComponentComponent } from './report-component/report-component.component';
import { VirtualibanUserComponent } from './virtualiban-user/virtualiban-user.component';
import { TigranReportComponent } from './tigran-report/tigran-report.component';
import { DocumentForInvestorComponent } from './document-for-investor/document-for-investor.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/home/list-wallet', pathMatch: 'full' },
    {
      path: 'home',
      component: HomeComponent,
      children: [
        {
          path: 'create-wallet',
          component: WalletFormComponent
        },
        {
          path: 'pending-requests',
          component: PendingRequestListComponent
        },
        {
          path: 'users-credential',
          component: UsersCredentialComponent
        },
        {
          path: 'list-wallet',
          component: WalletListComponent
        },
        {
          path: 'stats',
          component: StatsComponent
        },
        {
          path: 'new-servicer',
          component: NewServicerComponent
        },
        {
          path: 'new-company',
          component: NewcompanyComponent
        },
        {
          path: 'new-originator',
          component: NewBankComponent
        },
        {
          path: 'new-partner',
          component: NewPartnerComponent
        },
        {
          path: 'new-investor',
          component: NewInvestorComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        },
        {
          path: 'usury-rates',
          component: UsuryRatesComponent
        },
        {
          path: 'usury-rates/create',
          component: CreateUsuryRateComponent
        },
        {
          path: 'tigran',
          component: TigranComponent
        },
        {
          path: 'lemonway',
          component: LemonwayComponent
        },
        {
          path: 'lemonway/:type',
          component: LemonwayComponent
        },
        {
          path: 'report',
          component: ReportComponentComponent
        },
        {
          path: 'reportTigran',
          component: TigranReportComponent
        },
        {
          path: 'iban',
          component: VirtualibanUserComponent
        },
        {
          path: 'docForInvestor',
          component: DocumentForInvestorComponent
        }
      ],
      data: { title: extract('Home'), role: Utilities.userType[1] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {}
