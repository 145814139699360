import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeInvestorRoutingModule } from './home-investor-routing.module';
import { HomeInvestorComponent } from './home-investor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { PendingInvestorComponent } from './pending-investor/pending-investor.component';
import { ContrattoInvestitoreComponent } from './contratto-investitore/contratto-investitore.component';

@NgModule({
  declarations: [HomeInvestorComponent, DashboardComponent, PendingInvestorComponent, ContrattoInvestitoreComponent],
  imports: [CommonModule, ClipboardModule, SharedModule, NgbModule, HomeInvestorRoutingModule, FormsModule]
})
export class HomeInvestorModule {}
