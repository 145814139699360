import { Component, Input, OnInit, Type } from '@angular/core';
import { Wallet } from '@app/models/Wallet.model';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { WalletEditModalComponent } from '@app/home/wallet-details/wallet-edit-modal/wallet-edit-modal.component';
import { Utilities } from '@app/utilities/Utilities';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartModalComponent } from '@app/home/wallet-details/chart-modal/chart-modal.component';
import * as _ from 'lodash';
import { Invoice } from '@app/models/Invoice.model';
import { NgbdModalConfirmCloseComponent } from '@app/home/wallet-details/wallet-details.component';
import { roboModalComponent } from '@app/home/wallet-details/robo-modal/robo-modal.component';
import { ModalAddOriginatorComponent } from '../modal-add-originator/modal-add-originator/modal-add-originator.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalListaCedentiComponent } from '../modal-lista-cedenti/modal-lista-cedenti.component';

@Component({
  selector: 'app-wallet-details-upper-info',
  templateUrl: './wallet-details-upper-info.component.html',
  styleUrls: ['./wallet-details-upper-info.component.scss']
})
export class WalletDetailsUpperInfoComponent implements OnInit {
  @Input() wallet: Wallet;
  public wallet2: Wallet;
  public daysLeft: number;
  public invoices: Invoice[];
  public invoicesChiuse: Invoice[];
  public invoicesInCorso: Invoice[];
  public invoicesChiuse2: Invoice[];
  invoiceStatus: string[];
  public id: string;
  private backupWallet: Wallet;

  public counterPlafondBlue: number;
  public valuePlafondBlue: number;
  public counterPlafondYellow: number;
  public valuePlafondYellow: number;

  constructor(
    public firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.invoiceStatus = Utilities.invoiceStatus;

    this.id = this.route.snapshot.paramMap.get('id');
    this.wallet = new Wallet();
    this.invoices = [];
    this.invoicesChiuse = [];
    this.invoicesInCorso = [];
    firebase
      .getWalletfromID(this.id)
      .then(doc => {
        if (doc.exists) {
          this.wallet = doc.data() as Wallet;
          this.backupWallet = { ...this.wallet };
          this.wallet.id = this.id;
          this.gestioneValueProgresBarYellow(this.wallet.productsNode[0]);
          this.gestioneValueProgresBarBlue(this.wallet.productsNode[1]);
          Utilities.getDaysLeft(this.wallet);
        } else {
        }
      })
      .catch(error => {});

    this.firebase.getInvoicesByID(this.id).subscribe(data => {
      this.invoices = data as Invoice[];

      if (this.firebase.isCompany()) {
        this.filterInvForUser();
      }
      if (this.firebase.isInvestor()) {
        this.filterInvForUserInvestor(data);
      }
      this.invoicesChiuse = { ...this.invoices };
      this.invoicesInCorso = { ...this.invoices };
      this.filterForConcluse();
      this.filterForInCorso();
      // Ordinamento per data dec
      this.invoicesInCorso = _.orderBy(
        this.invoicesInCorso,
        [invoice => invoice.cedenteName.toLowerCase(), 'dataFattura'],
        ['asc', 'desc']
      );
      this.invoicesChiuse = _.orderBy(
        this.invoicesChiuse,
        [invoice => invoice.cedenteName.toLowerCase(), 'dataFattura'],
        ['asc', 'desc']
      );
      // this.sortingService.setRequests(this.invoices);
      // this.sortingService.initialize();
      // this.setCharts();
    });
  }

  gestioneValueProgresBarBlue(walletBlue: any) {
    this.valuePlafondBlue = 100;
    if (walletBlue != null) {
      this.counterPlafondBlue = (walletBlue.counter / walletBlue.plafond) * 100;
    } else {
      this.counterPlafondBlue = 0;
    }
  }

  gestioneValueProgresBarYellow(walletYellow: any) {
    this.valuePlafondYellow = 100;
    if (walletYellow != null) {
      this.counterPlafondYellow = (walletYellow.counter / walletYellow.plafond) * 100;
    } else {
      this.counterPlafondYellow = 0;
    }
  }

  ngOnInit() {}

  edit() {
    const modalRef = this.modalService.open(WalletEditModalComponent, { size: 'xl', centered: true, scrollable: true });
    modalRef.componentInstance.walletToModify = this.wallet;
    // modalRef.componentInstance.ratingSystemPercentage = this.wallet.ratingSystemPercentage;
    modalRef.result.then(
      () => {
        // fulfilled
        Utilities.getDaysLeft(this.wallet);
      },
      () => {
        // rejected
        this.wallet = { ...this.backupWallet };
      }
    );
  }

  aggiungiInvestitore() {
    const modalRef = this.modalService.open(ModalAddOriginatorComponent, {
      size: 'l',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.walletToModify = this.wallet;
  }

  contollaCedenti() {
    const modalRef = this.modalService.open(ModalListaCedentiComponent, {
      size: 'l',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.walletToModify = this.wallet;
  }

  charts() {
    const modalRef = this.modalService.open(ChartModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.allInvoices = this.invoicesChiuse;
    modalRef.componentInstance.paymentDays = this.wallet.paymentDays;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // fulfilled
      },
      () => {
        // rejected
      }
    );
  }

  close() {
    const modalRef = this.modalService.open(MODALS['focusFirst']);
    modalRef.result.then(
      () => {
        // fulfilled
        this.wallet.closed = true;
        this.firebase.modWallet(this.wallet);
      },
      () => {
        // rejected
      }
    );
  }

  robo() {
    const modalRef = this.modalService.open(roboModalComponent, {
      size: 'sm',
      centered: true,
      scrollable: true
    });
    modalRef.result.then(
      result => {
        // Save
      },
      reason => {
        // Dismiss
      }
    );
  }
  filterInvForUser() {
    this.invoices = _.filter(this.invoices, item => item.uid === this.firebase.loggedUser.id);
  }
  filterInvForUserInvestor(data: any) {
    // console.log(data);
    var arr: any[] = [];
    for (var inv of data) {
      var inve: any = inv;
      if (inve.listaInvestitori) {
        for (var valore of inve.listaInvestitori) {
          if (valore.split('---')[0] === this.firebase.loggedUser.investorPIva) {
            arr.push(inve);
          }
        }
      }
    }
    this.invoices = arr;
  }

  filterForConcluse() {
    this.invoicesChiuse = _.filter(
      this.invoicesChiuse,
      item => item.status === this.invoiceStatus[0] || item.status === this.invoiceStatus[9]
    );
  }

  filterForInCorso() {
    this.invoicesInCorso = _.filter(
      this.invoicesInCorso,
      item => item.status !== this.invoiceStatus[0] && item.status !== this.invoiceStatus[9]
    );
  }

  closeWalletCondition(): boolean {
    // FIXME aggiustare la condizione di chiusura del wallet
    return false;
    // return (
    //   this.firebase.isAdmin() &&
    //   (this.wallet.daysLeft === -1 ||
    //     this.wallet.daysLeft === -2 ||
    //     (this.wallet.productsNode[0] &&
    //       this.wallet.productsNode[0].plafond - this.wallet.productsNode[0].counter <= 0) ||
    //     (this.wallet.productsNode[1] &&
    //       this.wallet.productsNode[1].plafond - this.wallet.productsNode[1].counter <= 0) ||
    //     (this.wallet.productsNode[2] &&
    //       this.wallet.productsNode[2].plafond - this.wallet.productsNode[2].counter <= 0)) &&
    //   !this.wallet.closed
    // );
  }
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmCloseComponent
};
