import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/models/User.model';
import { ModalProfileComponent } from '@app/profile/modal-profile/modal-profile.component';
import { Observable, Subscription } from 'rxjs';
import { PendingService } from '@app/pending.service';
import { Utilities } from '@app/utilities/Utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-request-list',
  templateUrl: './pending-request-list.component.html',
  styleUrls: ['./pending-request-list.component.scss']
})
export class PendingRequestListComponent implements OnInit, OnDestroy {
  public tipologia: string;
  public usersList: Observable<any[]>;
  public docsList: Observable<any[]>;
  public pendingUsers: any[] = [];
  public pendingDocs: any[] = [];
  sub: Subscription;

  constructor(
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private pending: PendingService,
    private router: Router
  ) {
    this.tipologia = 'users';
    this.firebase.createPageLog('User Visit Page pending Requests');
  }

  ngOnInit() {
    this.usersList = this.firebase.getUsersByVerified(false);
    this.sub = this.usersList.subscribe(data => {
      this.pendingUsers = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showModal(userIn: User) {
    const modalRef = this.modalService.open(ModalProfileComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.userInput = userIn;
    modalRef.componentInstance.showUpdateButton = false;
  }

  approve(obj: any, type: string) {
    switch (type) {
      case 'user': {
        this.firebase.approveUser(obj);
        break;
      }
      case 'doc': {
        this.firebase.approveDocs(obj);
      }
    }
    this.pending.dec(1);
  }

  loadPendingRequests(): void {
    switch (this.tipologia) {
      case 'users': {
        this.usersList = this.firebase.getUsersByVerified(false);
        this.sub = this.usersList.subscribe(data => {
          this.pendingUsers = data;
        });
        break;
      }
      case 'docs': {
        this.docsList = this.firebase.getInvoicesNotApproved();
        this.sub = this.docsList.subscribe(data => {
          //console.log(data);
          this.pendingDocs = data;
        });
        break;
      }
    }
  }

  info(data: any) {
    this.router.navigate(['/home/wallet-details/' + data.walletId]);
  } //status
}
