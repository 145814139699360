import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import { requireCheckboxesToBeCheckedValidator } from '@app/models/utils/require-checkboxes-to-be-checked.validator';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { DatePipe } from '@angular/common';
import { MailerService } from '@app/mailer/mailer.service';
import { environment } from '@env/environment.prod';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-new-bank',
  templateUrl: './new-bank.component.html',
  styleUrls: ['./new-bank.component.scss']
})
export class NewBankComponent implements OnInit {
  products: string[] = Utilities.productNames;
  originatorCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private lemonwayServicer: LemonwayService,
    private mailerService: MailerService
  ) {
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page new-originator');
  }

  ngOnInit() {}

  submit() {
    this.isSubmitted = true;
    if (!this.originatorCreationForm.valid && !this.verifyPass()) {
      this.originatorCreationForm.get('email').markAsTouched();
      this.originatorCreationForm.get('password').markAsTouched();
      this.originatorCreationForm.get('name').markAsTouched();
      this.originatorCreationForm.get('originatorPIva').markAsTouched();
      this.originatorCreationForm.get('originatorProducts').markAsTouched();
    } else {
      let pipe = new DatePipe('en-US');
      if (this.originatorCreationForm.value.nazione == '---') {
        alert('inserire una nazione valida');
        return;
      }
      const objectToSave = this.firebaseService.createOriginator(this.originatorCreationForm);

      if (this.originatorCreationForm.value.lemonwa) {
        this.lemonwayServicer
          .createaNewLegalAccount(
            objectToSave.originatorPIva,
            objectToSave.originatorSolicitorName,
            objectToSave.originatorSolicitorSurname,
            objectToSave.stato,
            1,
            objectToSave.email,
            objectToSave.originatorOfficeRegisteredStreet,
            objectToSave.legalAddressCAP,
            objectToSave.originatorOfficeRegisteredCity,
            pipe.transform(objectToSave.originatorSolicitorBirthdate, 'y/MM/dd'),
            objectToSave.originatorSolicitorBirthplace,
            'M',
            objectToSave.telefono,
            objectToSave.telefono,
            false,
            false,
            objectToSave.name,
            objectToSave.name,
            true,
            this.originatorCreationForm.value.REA_CODE
          )
          .subscribe(data => {
            // console.log(data);
            if (data.legalAccount) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            }
            if (data.code == 200) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else if (data.error.code == 152) {
              // utente esistente
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  objectToSave.lemonwayAccount = true;
                  objectToSave.statoLemonway = Utilities.statoLemon[3];
                }
              } catch (e) {
                console.log('exception');
              }
            }
            try {
              alert(data.error.message);
            } catch (e) {}
          });
      } else {
        objectToSave.lemonwayAccount = false;
      }
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);
          this.mailerService.sendEmailNewUser(objectToSave.email).subscribe(() => {});

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          if (this.firebaseService.isAdmin()) {
            this.router.navigate(['/home/users-credential']);
          } else {
            this.router.navigate(['/partner/dashboard']);
          }
        })
        .catch(error => {
          // Handle Errors here.
          const errorCode = error.code;
          this.errorMessage = error.message;
          // ...
        });
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.originatorCreationForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      originatorCreditsCheck: new FormControl(false),
      name: new FormControl('', [Validators.required]),
      originatorPIva: new FormControl('', [Validators.required]),
      originatorWebSite: new FormControl(''),
      originatorPec: new FormControl(''),
      // prodotti
      originatorProducts: new FormGroup(
        {
          originatorFintech: new FormControl(false),
          originatorSupply: new FormControl(false),
          originatorBlue: new FormControl(false)
        },
        requireCheckboxesToBeCheckedValidator()
      ),
      // sede legale
      originatorOfficeRegisteredStreet: new FormControl(''),
      originatorOfficeRegisteredCity: new FormControl(''),
      originatorOfficeRegisteredProvince: new FormControl(''),
      // sede operativa
      originatorOfficeOperationalStreet: new FormControl(''),
      originatorOfficeOperationalCity: new FormControl(''),
      originatorOfficeOperationalProvince: new FormControl(''),
      // rappresentante legale
      originatorSolicitorSurname: new FormControl('', [Validators.required]),
      originatorSolicitorName: new FormControl('', [Validators.required]),
      originatorSolicitorBirthplace: new FormControl(''),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      // rappresentante amministrativo
      originatorAdminReferentSurname: new FormControl(''),
      originatorAdminReferentName: new FormControl(''),
      originatorAdminReferentTelephone: new FormControl(''),
      originatorAdminReferentEmail: new FormControl(''),
      lemonway: new FormControl(false),
      tel: new FormControl(''),
      indirizzo: new FormControl(''),

      nazione: new FormControl('---'),
      REA_CODE: new FormControl(''),
      viaLeg: new FormControl(''),
      comuneLeg: new FormControl(''),
      provinciaLeg: new FormControl(''),
      nazioneLeg: new FormControl(''),
      capLegale: new FormControl(''),
      legalAddressCAP: new FormControl('')
    });
  }

  get email() {
    return this.originatorCreationForm.get('email');
  }

  get password() {
    return this.originatorCreationForm.get('password');
  }

  get name() {
    return this.originatorCreationForm.get('name');
  }

  get originatorPIva() {
    return this.originatorCreationForm.get('originatorPIva');
  }

  get originatorProducts() {
    return this.originatorCreationForm.get('originatorProducts');
  }

  get originatorSolicitorBirthdate() {
    return this.originatorCreationForm.get('originatorSolicitorBirthdate');
  }

  get originatorSolicitorName() {
    return this.originatorCreationForm.get('originatorSolicitorName');
  }

  get originatorSolicitorSurname() {
    return this.originatorCreationForm.get('originatorSolicitorSurname');
  }

  verifyPass() {
    console.log(this.originatorCreationForm.get('password').value);
    console.log(this.originatorCreationForm.get('email').value);

    if (
      this.originatorCreationForm.get('password').value == this.originatorCreationForm.get('email').value ||
      this.originatorCreationForm.get('password').value ==
        this.originatorCreationForm.get('originatorSolicitorName').value ||
      this.originatorCreationForm.get('password').value ==
        this.originatorCreationForm.get('originatorSolicitorSurname').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
