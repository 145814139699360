import { Component, OnInit, Input, Type, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from '@app/models/Invoice.model';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Utilities } from '@app/utilities/Utilities';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Wallet } from '@app/models/Wallet.model';
import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MailerService } from '@app/mailer/mailer.service';
import { Investor } from '@app/models/Investor.model';
import { ModalAddOriginatorComponent } from '../modal-add-originator/modal-add-originator/modal-add-originator.component';
import { Observable, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { InvestitorePercentuale } from '@app/models/InvestitorePercentuale';
import { Company } from '@app/models/Company.model';
import { Assignor } from '@app/models/Assignor.model';
import { Originator } from '@app/models/Originator.model';
import { ChartModalComponent } from '../chart-modal/chart-modal.component';
import { GeneraContrattoComponent } from '@app/home/genera-contratto/genera-contratto.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { iteratee } from 'lodash';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Options } from '@angular-slider/ngx-slider';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { formatCurrency } from '@angular/common';

import { Inject, LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-request-detail-modal',
  templateUrl: './request-detail-modal.component.html',
  styleUrls: ['./request-detail-modal.component.scss']
})
export class RequestDetailModalComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;
  @Input() invoice_id: string;
  @Input() wallet: Wallet;
  @Input() Investor: Investor;

  @ViewChild('OTPSMS', { static: false }) myNameElem: ElementRef;

  loadingGeneral = false;

  show = true;
  isLoading = false;
  associaInvestitore!: FormGroup;
  data;
  verificaOTP!: FormGroup;

  invoiceStatus: string[] = Utilities.invoiceStatus;
  invoiceStatus_it_IT: string[] = Utilities.invoiceStatus_it_IT;
  invoiceStatus_Label_Stepper: string[] = Utilities.invoiceStatus_Label_Stepper;
  maturity = 0;
  controproposta: number;
  sconto: number;
  uploadPercentage: string;
  uploading: boolean;
  fileToUpload: any;
  notaUrl: any;
  bonificoUrl: any;
  contrattoURl: any;

  yellowValidate: boolean;
  blueValidate: boolean;
  azureValidate: boolean;

  usersInvestor: any;

  investitore: string;
  listInvestorOfWallet: any = [];
  listInvestorOfWalletNonPaganti: any = [];
  listInvestorOfWalletObj: any[];

  usersList: Observable<any[]>;
  usersItems: any[];
  caricaUsers: any[] = [];
  sub: Subscription;
  loading = false;

  listInvestitori: string[];
  listaInvestitoriPagamento: any = [];
  listaInvestitoriNonPaganti: any = [];
  listaInvestitoriPagato: any[];
  listaProposteInvestimento: any = [];
  percentualeCoperta = 0;
  percentualeScoperta = 0;

  infoCurrentInvestor: any = [];

  controlloPercentuale = false;
  Listotp: string[];
  percentualeSalvata = 0;
  investitoreCorrentePercentuale = 0;
  mat = Math;

  investitorePagato: boolean;

  cedente: Assignor;
  debitore: Originator;

  listaInvestitoriDaPagare: string[];

  value = 0;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  highValue = 100;
  valoreFinale = 0;
  filename: string;
  file64Encode: string;

  //  invoice: Invoice;
  private statusSubmitOTP = true;
  private countCall = 0;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public datepipe: DatePipe,
    public activeModal: NgbActiveModal,
    public firebase: FirebaseTranchedService,
    private storage: AngularFireStorage,
    private mailerService: MailerService,
    private _modalService: NgbModal,
    private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private sms: SmsService,
    private lemonway: LemonwayService,
    private sendMail: MailerService
  ) {
    this.yellowValidate = false;
    this.blueValidate = false;
    this.azureValidate = false;

    this.debitore = new Originator();

    this.createFormInvestor();
    //this.createFormOTP();
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    console.log('Destroy');
  }

  ngOnInit() {
    //console.log('ID==>', this.invoice);

    const date = new Date();
    this.data = date.toLocaleDateString();
    // recupero la company a partire dall'id della fattura => uid
    const uuid_company = this.invoice.uid;
    // console.log(uuid_company);
    this.firebase.getUserByID(uuid_company).then(value => {
      if (value.exists) {
        // modificare qui
        this.cedente = value.data() as Assignor;

        // console.log('CEDENTE');
        // console.log(this.cedente);
      }

      this.firebase.getUserByID(this.wallet.originatorUId).then(valueDebt => {
        if (valueDebt.exists) {
          // modificare qui
          this.debitore = valueDebt.data() as Originator;
          // console.log('debitore=>', this.debitore);
        }

        // **********************************************************

        const firstDate = new Date(this.invoice.dataScadenza);
        const secondDate = new Date();
        const diff = firstDate.getTime() - secondDate.getTime();
        this.maturity = Math.ceil(diff / (1000 * 3600 * 24));

        if (this.invoice.scontoAccettato) {
          this.sconto = this.invoice.scontoAccettato;
        } else {
          this.sconto = this.invoice.scontoProposto;
        }

        if (
          this.invoice.status == this.invoiceStatus[6] ||
          this.invoice.status == this.invoiceStatus[7] ||
          this.invoice.status == this.invoiceStatus[8]
        ) {
          const ref = this.storage.ref(this.invoice.id + '-NC.pdf');
          this.notaUrl = ref.getDownloadURL();
        }

        if (this.invoice.status == this.invoiceStatus[7] || this.invoice.status == this.invoiceStatus[8]) {
          const ref = this.storage.ref(this.invoice.id + '-BON.pdf');
          this.bonificoUrl = ref.getDownloadURL();
        }

        if (this.invoice.status_index > 30 && this.invoice.status_index < 50) {
          const ref = this.storage.ref(this.invoice.id + '-CONTRATTOFIRMATO.pdf');
          this.contrattoURl = ref.getDownloadURL();
        }
        this.listaProposteInvestimento = this.invoice.listaProposteInvestimento
          ? this.invoice.listaProposteInvestimento
          : [];
        this.listaInvestitoriNonPaganti = [];
        this.investitore = this.invoice.investor ? this.invoice.investor : null;
        this.listInvestitori = this.invoice.listaInvestitori ? this.invoice.listaInvestitori : [];
        this.listaInvestitoriPagamento = this.invoice.listaInvestitoriPagamento
          ? this.invoice.listaInvestitoriPagamento
          : [];
        this.listaInvestitoriPagamento.forEach(element => {
          // console.log(element);
          const inv = element.split('---')[0];
          const per: number = +element.split('---')[1];
          const bool = element.split('---')[2];
          this.percentualeSalvata = this.percentualeSalvata + per;
          // if (bool == 'F') {
          //   this.listaInvestitoriNonPaganti.push([inv,per.toFixed(16)]);
          // }
        });

        this.Listotp = this.invoice.otp ? this.invoice.otp : [];

        // this.listInvestorOfWallet = this.wallet.listInvestitori;
        this.listInvestorOfWallet = [];
        // Il metodo seleziona gli investitori sulla base della associazione al wallet
        /*
            this.wallet.listInvestitori.forEach(investor => {
              this.firebase.getUserByID(investor).then(value => {
                if (value.exists) {
                  const us = value.data() as Investor;
                  this.listInvestorOfWallet.push(us.investorPIva);
                  // console.log(investor);
                }
              });
            });
            */
        // Questo metodo permette di selezionare tutti gli investitori in fase di selezione
        this.firebase.getUsersByRole(Utilities.userType[5]).subscribe(data => {
          data.forEach(investor => {
            if (investor.investorPIva && investor.investorPIva != '') {
              this.listInvestorOfWallet.push([investor.investorPIva, investor.email]);
            }
          });
          // console.log('investor=>',this.listInvestorOfWallet);
        });

        this.listInvestorOfWalletNonPaganti = [];

        if (this.invoice.status_index != 36 && this.invoice.status_index != 37) {
          this.listaInvestitoriDaPagare = this.listaInvestitoriPagamento;
          this.invoice.listaInvestitoriDaPagare = this.listaInvestitoriDaPagare;
          // TODO: verificare funzione in altri step al momento non sembra necessario
          // this.firebase.modInvoice(this.invoice);
        }
        this.listaInvestitoriDaPagare = this.invoice.listaInvestitoriDaPagare
          ? this.invoice.listaInvestitoriDaPagare
          : [];

        this.valoreFinale =
          this.invoice.importoFattura - (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100;

        // TODO: verificare la funzionalita
        /*
          if (this.invoice.status_index == 32 && this.invoice.listaInvestitori) {
            this.invoice.listaInvestitori = [];
            this.firebase.modInvoice(this.invoice);
          }
          */

        if (this.invoice.status_index == 36) {
          this.verificaPagamentoInvestitori();
        }

        if (this.invoice.status_index > 31) {
          this.recalculateCopertura();
          if (this.firebase.isInvestor()) {
            this.investitorePagato = this.verificaPagamenti(this.firebase.loggedUser.investorPIva);
          }
        }
      });
    });
  }

  createFormInvestor() {
    this.associaInvestitore = this.formBuilder.group({
      pivaInvestitore: new FormControl('', [Validators.required]),
      percentuale: new FormControl('', [Validators.required]),
      rate: new FormControl(false)
    });
  }

  // @hydra 2022-01-14 -- @author - Gaetano Bitonte - https://hydrasolutions.atlassian.net/browse/FXINPROD-87

  verificaPagamenti(piva: string): boolean {
    // ritorna false se non ha pagato
    try {
      if (this.listaInvestitoriNonPaganti[0].includes(piva)) {
        return false;
      }
    } catch (e) {}

    for (const investor of this.listaInvestitoriPagamento) {
      const invArr = investor.split('---');
      if (invArr[0] == piva) {
        this.infoCurrentInvestor = invArr;
      }
      if (invArr[0] == piva && invArr[2] == 'V') {
        return true;
      }
    }
    return false;
  }

  generaContrattoPDF() {
    const modalRef = this._modalService.open(GeneraContrattoComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = this.invoice;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.componentInstance.cedente = this.cedente;
    modalRef.result.then(
      () => {
        // fulfilled
      },
      () => {
        // rejected
      }
    );
  }

  submitInvestiore() {
    if (this.percentualeCoperta != 100) {
      return;
    }

    const arr = [];
    const listaInv = [];
    const listaDaPagare = [];
    // Se ha inserito due volte lo stesso con percentuali differenti somma le due percentuali
    this.listInvestitori.forEach(element => {
      const inv = element.split('---')[0];
      let percentuale = 0;
      if (!arr.includes(inv)) {
        this.listInvestitori.forEach(element2 => {
          const inv2 = element2.split('---')[0];
          if (inv == inv2) {
            const perc: number = +element2.split('---')[1];
            percentuale = percentuale + perc;
          }
        });
        listaInv.push(inv + '---' + percentuale);
        listaDaPagare.push(inv + '---' + percentuale + '---' + 'F');
        arr.push(inv);
      }
    });
    this.listInvestitori = listaInv;
    this.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitori = listaInv;
    this.invoice.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitoriDaPagare = listaDaPagare;

    // TODO: Da modificare in base al nuovo criterio di completamento
    for (let i = 0; i < this.listaProposteInvestimento.length; i++) {
      var element2 = this.listaProposteInvestimento[i];
      if (this.controllo(element2) == true) {
        var piva = element2.split('---')[0];
        this.firebase.getUserByRoleAndStringField(Utilities.userType[5], 'investorPIva', piva).subscribe(data => {
          //console.log(data);
          var subject = 'PIATTAFORMA FX12';
          var mailText =
            "L'investimento proposto per il wallet:" +
            this.wallet.walletName +
            ' per la fattura numero:' +
            this.invoice.numFattura +
            '  è stato rifiutato.';
          var email = data[0].email;
          this.mailerService.sendEmail(email, subject, mailText).subscribe(() => {
            //  console.log('send mail');
          });
        });
      }
    }

    // console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    this.activeModal.close('');
    this.adminChangeStatusIndex(33);
  }

  submitInvestiore23() {
    const obj = {
      status_index: 33
    };
    this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
    this.activeModal.close('');
  }

  submitInvestioreDaInvestitore() {
    // console.log(this.associaInvestitore.value.percentuale);
    if (this.associaInvestitore.value.percentuale <= 0 || this.associaInvestitore.value.percentuale > 100) {
      this.controlloPercentuale = true;
      return;
    }
    var controllo = false;
    var inv = this.firebase.loggedUser.investorPIva;
    var perc = this.associaInvestitore.value.percentuale;

    for (let i = 0; i < this.listaProposteInvestimento.length; i++) {
      var element2 = this.listaProposteInvestimento[i];
      const inv2 = element2.split('---')[0];
      if (inv == inv2) {
        this.listaProposteInvestimento[i] = inv + '---' + perc + '---' + 'S';
        controllo = true;
      }
    }

    if (controllo == false) {
      this.listaProposteInvestimento.push(inv + '---' + perc + '---' + 'S');
    }

    this.invoice.listaProposteInvestimento = this.listaProposteInvestimento;

    // console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    this.activeModal.close('');
  }

  submitInvestioreVer2() {
    this.isLoading = true;
    const arr = [];
    const listaInv = [];
    const listaDaPagare = [];
    // this.percentualeCoperta=this.invoice.percentualeCoperta?this.invoice.percentualeCoperta:0;
    // Se ha inserito due volte lo stesso con percentuali differenti somma le due percentuali
    this.listInvestitori.forEach(element => {
      const inv = element.split('---')[0];
      let percentuale = 0;
      if (!arr.includes(inv)) {
        this.listInvestitori.forEach(element2 => {
          const inv2 = element2.split('---')[0];
          if (inv == inv2) {
            const perc: number = +element2.split('---')[1];
            percentuale = percentuale + perc;
          }
        });
        listaInv.push(inv + '---' + percentuale);
        if (this.listaInvestitoriNonPaganti[0].includes(inv)) {
          listaDaPagare.push(inv + '---' + percentuale + '---' + 'F');
        } else {
          listaDaPagare.push(inv + '---' + percentuale + '---' + 'V');
        }
        arr.push(inv);
      }

      /* invio email di notifica agli investitori associati */

      this.firebase.getInvestorByPIva(inv).subscribe(data => {
        console.log('Investor=>', data);

        // invio email di notifica
        // creo il body dell'email

        const body =
          '{\n' +
          '    "to":"' +
          data[0].email +
          '",\n' +
          '    "subject":"Hai una nuova operazione",\n' +
          '    "senderCompany":"FX12 Srl",\n' +
          '    "from":"support@incassaora.it",\n' +
          '    "senderName":"Support of Incassaora",\n' +
          '    "templateName":"newOperation",\n' +
          '    "denominazioneCedente":"' +
          this.cedente.name +
          '",\n' +
          '    "denominiazioneDebitore":"' +
          this.debitore.name +
          '",\n' +
          '    "morescoreDebitore":"' +
          this.debitore.originatorMoreScore +
          '",\n' +
          '    "pdDebitore":"' +
          this.debitore.originatorDefault +
          '",\n' +
          '    "morescoreCedente":"' +
          this.cedente.assignorMoreScore +
          '",\n' +
          '    "pdCedente":"' +
          this.cedente.assignorDefault +
          '"\n' +
          '}';

        // console.log('body=>',body);
        this.sendMail.sendEmailTemplateByBody(body);
      });
    });

    this.listInvestitori = listaInv;
    this.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitori = listaInv;
    this.invoice.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitoriDaPagare = listaDaPagare;
    // console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    this.percentualeSalvata = this.percentualeCoperta;
    this.adminChangeStatusIndex(33);
    this.isLoading = false;

    this.activeModal.close('');
  }

  adminChangeStatusIndex(value: number) {
    if (value) {
      this.invoice.status_index = value;
      this.firebase.modInvoice(this.invoice).then(resp => {
        this.activeModal.close('');
        window.location.reload();
      });
    }
  }

  accettaFattura(proposta: boolean) {
    if (proposta) {
      this.invoice.status = Utilities.invoiceStatus[1];
      this.firebase.modInvoice(this.invoice);
    } else {
      this.invoice.status = Utilities.invoiceStatus[0];
      this.firebase.modInvoice(this.invoice);
    }
  }

  controproponi(contropropost: boolean) {
    if (contropropost) {
      // Fa controproposta
      this.invoice.status = Utilities.invoiceStatus[2];
      this.invoice.controproposta = this.controproposta;
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      // this.sendMailToOriginator('getIpotesiFattura', 'Controproposta');
      this.adminChangeStatusIndex(2);

      // invio email di notifica
      // creo il body dell'email

      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "firstname":"' +
        this.debitore.originatorSolicitorName +
        '",\n' +
        '    "lastname":"' +
        this.debitore.originatorSolicitorSurname +
        '",\n' +
        '    "subject":"Controproposta ricevuta",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"newCounterOffer",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.assignorName +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '",\n' +
        '    "importoFattura":"' +
        this.invoice.importoFattura +
        '",\n' +
        '    "dataFattura":"' +
        this.invoice.dataFattura +
        '",\n' +
        '    "numeroFattura":"' +
        this.invoice.numFattura +
        '",\n' +
        '    "maturity":"' +
        this.invoice.dataScadenza +
        '",\n' +
        '    "controProposta":"' +
        this.controproposta +
        '"\n' +
        '}';

      console.log('body=>', body);

      this.sendMail.sendEmailTemplateByBody(body);

      // ///

      /* ******************************* */
    } else {
      this.invoice.status = Utilities.invoiceStatus[3];
      this.invoice.controproposta = this.invoice.scontoProposto;
      this.invoice.scontoAccettato = this.invoice.controproposta;
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      // this.sendMailToFornitore('getPropostaAccettata', 'Controproposta Accettata', this.invoice.uid);
      this.adminChangeStatusIndex(5);

      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "firstname":"' +
        this.debitore.originatorSolicitorName +
        '",\n' +
        '    "lastname":"' +
        this.debitore.originatorSolicitorSurname +
        '",\n' +
        '    "subject":"Proposta accettata",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"proposalAccepted",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.assignorName +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '",\n' +
        '    "importoFattura":"' +
        this.invoice.importoFattura +
        '",\n' +
        '    "dataFattura":"' +
        this.invoice.dataFattura +
        '",\n' +
        '    "numeroFattura":"' +
        this.invoice.numFattura +
        '",\n' +
        '    "maturity":"' +
        this.invoice.dataScadenza +
        '",\n' +
        '    "controProposta":"' +
        this.controproposta +
        '"\n' +
        '}';

      console.log('body=>', body);

      this.sendMail.sendEmailTemplateByBody(body);
    }
  }

  accept(accepted: boolean) {
    if (accepted) {
      this.invoice.status = Utilities.invoiceStatus[3];
      this.invoice.scontoAccettato = this.invoice.controproposta;
      this.firebase.modInvoice(this.invoice);
      this.sendMailToFornitore('getPropostaAccettata', 'Controproposta Accettata', this.invoice.uid);
      this.adminChangeStatusIndex(5);
    } else {
      this.invoice.status = Utilities.invoiceStatus[2];
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      this.sendMailToOriginator('getPropostaRifiutata', 'Rifiutata');
      this.adminChangeStatusIndex(3);
    }
  }

  chiudi() {
    this.invoice.status = Utilities.invoiceStatus[0];
    this.firebase.modInvoice(this.invoice);
    this.adminChangeStatusIndex(3);
  }

  yellow() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Yellow Finance') {
        console.log('Name veicolo=>', veicolo);
        console.log('index_veicolo =>', index_veicolo);

        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.yellowValidate = true;
        } else {
          this.yellowValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(10);
        }
      }
      index_veicolo++;
    });
  }

  blue() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Blue Finance') {
        console.log('Name veicolo=>', veicolo);
        console.log('index_veicolo =>', index_veicolo);

        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.blueValidate = true;
        } else {
          this.blueValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(30);
        }
      }
      index_veicolo++;
    });
  }

  azure() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Azure Finance') {
        console.log('Name veicolo=>', veicolo);
        console.log('index_veicolo =>', index_veicolo);

        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.azureValidate = true;
        } else {
          this.azureValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(50);
        }
      }
      index_veicolo++;
    });
  }

  addInvestiore() {
    const arr: InvestitorePercentuale = new InvestitorePercentuale();
    this.controlloPercentuale = false;
    if (this.associaInvestitore.value.pivaInvestitore == '---') {
      this.controlloPercentuale = true;
      return;
    }
    if (this.associaInvestitore.value.percentuale <= 0) {
      this.controlloPercentuale = true;
      return;
    }
    arr.investorPiva = this.associaInvestitore.value.pivaInvestitore;
    arr.percentuale = this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(10);
    this.percentualeCoperta = parseFloat(a);
    const new_perc = (this.percentualeCoperta + this.associaInvestitore.value.percentuale).toFixed(10);
    if (new_perc > 100) {
      this.controlloPercentuale = true;
      this.associaInvestitore.reset();
      this.associaInvestitore.reset();
      return;
    }
    this.percentualeCoperta = this.percentualeCoperta + this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(10);
    this.percentualeCoperta = parseFloat(a);
    this.associaInvestitore.reset();
    this.associaInvestitore.reset();
    this.listInvestitori.push(arr.investorPiva + '---' + arr.percentuale);
    /* to split the string do so
    var prova=arr.investorPiva+"---"+arr.percentuale;
    let toArray = prova.split("---");
    console.log(toArray);
    */
    // console.log(this.listInvestitori);
  }

  controllo(inv: string) {
    for (let i = 0; i < this.listInvestitori.length; i++) {
      var elem = this.listInvestitori[i];
      if (elem.split('---')[0] == inv.split('---')[0]) {
        return false;
      }
    }
    return true;
  }

  approvaTransazione(value: boolean) {
    // const verifica = document.getElementById('verificaOTP');
    console.log('APPTOVAA');
    let avvio = true;
    if (value) {
      // attivo textbox per inserimento otp

      this.firebase.getInvoiceByID(this.invoice.id).subscribe(resp => {
        this.show = true;

        // genero OTP SMS e apro campo per inserimento e conferma
        // TODO: chiamata verso piattaforma laravel per generazione SMS
        // Chiamata verso piattaforma laravel per generazione sms
        // console.log('Genera OTP');

        let investorPhone = '';

        investorPhone = this.firebase.loggedUser.telefono;
        // console.log('phone1:' + investorPhone);

        // tslint:disable-next-line:triple-equals
        console.log('avvio=>', avvio);
        if (investorPhone != '' && avvio) {
          avvio = false;
          console.log('avvio=>', avvio);
          console.log('INVIOOOOO');
          this.sms.sendSms(investorPhone).subscribe(data2 => {
            // console.log(data2);
            const otp = data2.otp;
            const response_status = data2.response_status;
            if (otp !== '' && response_status == 'OK') {
              this.firebase.createSmsLog(otp, this.firebase.loggedUser.investorPIva);
              // let date = new Date().toUTCString();
              const date = new Date().getTime();

              this.Listotp = [];
              try {
                if (resp[0].otp) {
                  this.Listotp = resp[0].otp;
                }
              } catch (err) {
                this.Listotp = [];
              }

              this.Listotp.push(otp + '---' + this.firebase.loggedUser.investorPIva + '---' + date);

              const obj = {
                otp: this.Listotp
              };

              console.log('Oggetto=>', obj);
              this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(rest => {
                // this.activeModal.close('');
                // window.location.reload();
              });

              // tslint:disable-next-line:max-line-length
              alert(
                'Abbiamo inviato un codice al tuo numero: ' +
                  investorPhone +
                  '\n Il codice sarà valido solo per 10 minuti!'
              );
            } else {
              alert(
                "Si è verificato un errore durante l'invio del SMS. Si prega di riprovare oppure contattare l'assistenza tecnica!"
              );
              this.activeModal.close('');
              window.location.reload();
            }
          });
        }
      });
    } else {
      this.show = false;
    }
  }

  // createFormOTP() {
  //   this.verificaOTP = this.formBuilder.group({
  //     OTPSMS: new FormControl('', [Validators.required]),
  //     rate: new FormControl(false)
  //   });
  // }

  ritarda() {
    this.statusSubmitOTP = false;
    let countExec = 0;
    setTimeout(() => {
      countExec++;
      // <<<---using ()=> syntax
      console.log('countExec=>', countExec);
      this.submitOTP();
    }, Math.floor(Math.random() * 3000));
  }

  async submitOTP() {
    /* ricarico la fattura prima di procedere ad una sua modifica */
    this.statusSubmitOTP = false;
    console.log('this.investitorePagato=>', this.investitorePagato);
    if (!this.investitorePagato) {
      this.firebase.getInvoiceByID(this.invoice.id).subscribe(resp => {
        this.isLoading = false;
        const value = this.myNameElem.nativeElement.value;
        console.log('OTP INS =>', value);
        if (value) {
          let verifica = false;
          // il tempo massimo di attesa prima dell'inserimento dell'otp è 10 minuti calcolato in millisecondi
          const timeDelay = 10 * 60 * 1000;

          this.Listotp = [];
          try {
            if (resp[0].otp) {
              this.Listotp = resp[0].otp;
            }
          } catch (err) {
            this.Listotp = [];
          }

          this.Listotp.forEach(element => {
            // console.log(element);
            // console.log(this.firebase.loggedUser.investorPIva);
            // splitto gli elementi nell'array
            const arr = element.split('---');
            // tslint:disable-next-line:triple-equals
            const dateNow = new Date().getTime();
            const dateDelay = dateNow - timeDelay;
            //  console.log(dateNow);
            if (arr[1] == this.firebase.loggedUser.investorPIva) {
              //   console.log('Investitore presente');
              if (arr[0] == value) {
                // verific a delle date
                // @ts-ignore
                if (dateDelay < arr[2]) {
                  verifica = true;

                  // console.log('OTP corretto');
                }
              }
            }
          });

          console.log('Finito 1 foreach');
          console.log('verifica=>', verifica);

          if (verifica) {
            // Sostituire con l'otp che è salvato attualmente in this.otp
            // TODO: se il codice è corretto allora verificare se vi sono fondi ed avviare il trasferimento di fondi
            const fondi = 10000000;

            // TODO: recuperare il valore dei fondi da Laravel tramite API a LemonWay
            // La partita IVA dell'investiotre è attualmente associata

            // verifica che i findi siano maggiori di quanto bisogna versare per chiudere la trattativa
            // tslint:disable-next-line:max-line-length
            let prezzofinale =
              this.invoice.importoFattura - (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100;

            if (fondi > prezzofinale) {
              // chiamate alle API Laravel e verso LemonWay per il trasferimento

              // tslint:disable-next-line:no-shadowed-variable
              this.firebase.getUserByID(this.invoice.uid).then(value => {
                // console.log('PIVA Forniotore:' + value.data().pIva);
                this.listaInvestitoriPagamento.forEach(elemINV => {
                  const investitoreArr = elemINV.split('---');
                  if (investitoreArr[0] === this.firebase.loggedUser.investorPIva) {
                    const per: number = +investitoreArr[1];
                    prezzofinale = (prezzofinale * per) / 100;
                  }
                });

                console.log('Finito 2 foreach');

                // console.log(prezzofinale);
                prezzofinale = prezzofinale * 100;
                const valoreForLemonway: number = Math.trunc(prezzofinale);
                console.log('valoreForLemonway=>', valoreForLemonway);

                const comment = 'Fatt. ' + this.invoice.numFattura + '|' + this.invoice.id;

                // let countCall = 0;
                console.log('this.countCall=>', this.countCall);
                if (this.countCall === 0) {
                  alert('ALERT: Trasferimento in corso');
                  verifica = false;
                  this.countCall++;
                  console.log(this.countCall);
                  this.lemonway
                    .transaction(valoreForLemonway, value.data().pIva, this.firebase.loggedUser.investorPIva, comment)
                    .subscribe(data => {
                      const test = true;
                      let pass = 0;

                      try {
                        try {
                          pass = data.transaction.status;
                        } catch (e) {
                          pass = 0;
                        }

                        if (pass === 3) {
                          this.investitorePagato = true;

                          // Modifichiamo il suo valore nell array
                          const arr = [];
                          let num = 0;

                          this.listaInvestitoriPagamento = [];
                          try {
                            if (resp[0].listaInvestitoriPagamento) {
                              this.listaInvestitoriPagamento = resp[0].listaInvestitoriPagamento;
                            }
                          } catch (err) {
                            this.listaInvestitoriPagamento = [];
                          }

                          this.listaInvestitoriPagamento.forEach(elem => {
                            if (elem.split('---')[0] != this.firebase.loggedUser.investorPIva) {
                              arr.push(elem);
                            } else {
                              arr.push(
                                this.firebase.loggedUser.investorPIva + '---' + elem.split('---')[1] + '---' + 'V'
                              );
                            }
                          });

                          this.listaInvestitoriPagamento = arr;
                          this.invoice.listaInvestitoriPagamento = arr;
                          this.invoice.listaInvestitoriDaPagare = arr;

                          this.listaInvestitoriPagamento.forEach(elem => {
                            if (elem.split('---')[2] == 'F') {
                              num = num + 1;
                            }
                          });

                          console.log('Finito 3 foreach');

                          console.log('Pagamenti 1 =>', this.listaInvestitoriPagamento);

                          const obj = {
                            listaInvestitoriPagamento: arr,
                            listaInvestitoriDaPagare: arr
                          };
                          this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(resp => {
                            if (num > 0) {
                              // this.adminChangeStatusIndex(33);
                              this.isLoading = true;
                              this.activeModal.close();
                              window.location.reload();
                            } else {
                              console.log('Pagamenti 2 =>', this.listaInvestitoriPagamento);
                              const obj = {
                                status: Utilities.invoiceStatus[9],
                                status_index: 36
                              };
                              this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(resp => {
                                this.isLoading = true;
                                this.activeModal.close();
                                window.location.reload();
                              });
                            }

                            alert('Transazione Eseguita');
                          });
                        } else {
                          alert("Errore nella transazione! Se il problema persiste contattare l'amministratore");
                          this.isLoading = true;
                          this.activeModal.close('');
                        }
                      } catch (e) {
                        alert('Catch=>' + data.error.message);
                        this.isLoading = true;
                        this.activeModal.close('');
                      }
                    });
                }
              });
            } else {
              // in attesa di fondi
              alert(
                // tslint:disable-next-line:max-line-length
                "ALERT: Fondi non sufficienti a coprire l'importo richiesto.\n Effettuare una ricarica oppure contattare l'amministrazione"
              );
              // this.adminChangeStatusIndex(35);
              this.isLoading = true;
              this.activeModal.close('');
              window.location.reload();
            }
          } else {
            // TODO: messaggio di errore
            alert('ERRORE: Codice non valido!');
            this.isLoading = true;
            this.activeModal.close('');
            window.location.reload();
          }
        } else {
          alert('ERRORE: Codice non valido. Inserire un valore');
          this.isLoading = true;
          this.activeModal.close('');
        }
      });
    }

    /* ********************************************************** */
  } // end Submit

  clearOTP() {
    // svuota il campo otp e nasconde il div relativo
  }

  validateInvestorOTP_SMS(value: string) {}

  generaContratto() {
    //  console.log(this.invoice.uid);
    // la funzione getUserByID restituisce un oggetto di tipo Promise
    // la funzione then assegna alla variabile value quanto restituito
    // nel caso sotto tutta la collection specificata da cui è possibile
    // estrarre il documento
    this.firebase.getUserByID(this.invoice.uid).then(value => {
      // console.log("Legal:" + value.data().legalName);
      this.usersItems = [value.data()];
      // qui va generato tutto il pdf

      // genero un html element
      let contratto: HTMLElement;
      let contrattoText: string;
      contratto = document.createElement('div');
      // setto attributi come stile etc
      contratto.setAttribute('style', 'padding: 20px; font-size: 15px; color: red; align: center;');
      // inserisco testo con formattazione html
      contratto.innerHTML = '<h1>questo è un contratto tra fx12 e ' + value.data().legalName + '</h1>';
      let ibanValidato;
      let pecValidato;
      if (this.cedente.virtualIban) {
        ibanValidato = this.cedente.virtualIban;
      } else {
        ibanValidato = '_____________________________________';
      }
      if (this.cedente.assignorPec) {
        pecValidato = this.cedente.assignorPec;
      } else {
        pecValidato = '_____________________________________';
      }

      //sostituire qua
      contrattoText =
        this.cedente.denominazione +
        ' \n' +
        'Indirizzo sede legale\n' +
        '\n' +
        '\n' +
        'Alla cortese attenzione\n' +
        this.invoice.cedenteName +
        '\n' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        'Egregi Signori, \n' +
        'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.\n' +
        '\n' +
        'Oggetto: Contratto di cessione del credito \n' +
        '\n' +
        'Egregi Signori:\n' +
        '\n' +
        'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue:\n' +
        '\n' +
        '\n' +
        '\n' +
        'ACCORDO\n' +
        '\n' +
        'Tra\n' +
        '\n' +
        '- FX12 s.r.l., con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 in persona della Dott.ssa Rita Capitelli, che sottoscrive il presente accordo in qualità di amministratore e legale rappresentante (di seguito, “FX12” o il “Cessionario”)\n' +
        '- da una parte - \n' +
        'e\n' +
        '\n' +
        '-' +
        this.invoice.cedenteName +
        ', con sede legale in [n] (n), CF. ' +
        this.cedente.fiscalCode +
        ' e P.Iva ' +
        this.cedente.pIva +
        ', numero di iscrizione al Registro delle Imprese di NAPOLI n. [n], in persona del Sig. [Legale rappresentante], che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “' +
        this.invoice.cedenteName +
        '” o il “Cedente”); \n' +
        '- dall’altra parte –\n' +
        '\n' +
        'FX12 e ' +
        this.invoice.cedenteName +
        ', d’ora in avanti congiuntamente denominate le "Parti", e ciascuna singolarmente una "Parte");\n' +
        '\n' +
        'PREMESSO CHE\n' +
        '\n' +
        'FX12 è una start up innovativa che ha sviluppato la piattaforma fintech Incassa Ora® (di seguito, la “Piattaforma”) finalizzata alla negoziazione di crediti; \n' +
        '\n' +
        this.wallet.originatorName +
        ' è un’azienda dotata di un elevato merito creditizio, interessata ad accelerare i pagamenti ai propri fornitori, e che a tal fine ha aderito alla piattaforma Incassa Ora® di FX12 (di seguito anche il “Debitore Ceduto”); \n' +
        '\n' +
        this.invoice.cedenteName +
        ' è il fornitore di ' +
        this.wallet.originatorName +
        ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di ' +
        this.wallet.originatorName +
        '; \n' +
        '\n' +
        this.wallet.originatorName +
        ', in particolare, si è impegnata a corrispondere a ' +
        this.invoice.cedenteName +
        ' un importo, da liquidarsi entro ' +
        this.wallet.daysLeft +
        ' giorni dall’emissione della fattura;\n' +
        '\n' +
        this.invoice.cedenteName +
        ' ha manifestato l’intenzione di cedere e trasferire a FX12 attraverso la piattaforma Incassa Ora® il singolo credito risultante dalla fattura n. ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        'FX12 intende acquistare il credito secondo le modalità e termini di cui al presente contratto.\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        'TUTTO CIò PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente accordo (l\'"Accordo"), le Parti convengono quanto segue:\n' +
        '\n' +
        '1. Premesse \n' +
        '1.1. Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo.\n' +
        '\n' +
        '2. Oggetto\n' +
        '2.1 In forza della sottoscrizione del presente Accordo, il Cedente cede a titolo oneroso pro-soluto al Cessionario, che accetta, il credito risultante dalla fattura n° ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        ' di ' +
        this.invoice.importoFattura +
        '€ (di seguito, il “Credito”) che vanta nei confronti di ' +
        this.wallet.originatorName +
        '. \n' +
        '2.2 Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n°' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        ' (All. 1).\n' +
        '2.3 Dalla data di sottoscrizione del presente Accordo, quindi, FX12 diventa a tutti gli effetti di legge titolare del Credito ceduto. Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c..\n' +
        '\n' +
        '3. Prezzo\n' +
        '3.1 A fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente:\n' +
        '3.1.1 un importo pari a € ' +
        this.invoice.importoFattura +
        ' ([Prezzo d’acquisto]), entro ' +
        this.wallet.daysLeft +
        ' giorni lavorativi dalla sottoscrizione del presente Accordo.\n' +
        '3.2 I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
        ibanValidato +
        ' intestato a ' +
        this.invoice.cedenteName +
        '(All. 2). \n' +
        '\n' +
        '\n' +
        '\n' +
        '4. Garanzie e obblighi delle Parti\n' +
        '4.1 Il Cedente garantisce che:\n' +
        '\n' +
        'non è in stato di insolvenza, non esistono fatti o circostanze che potrebbero esporla a eventuali procedure concorsuali e non ha depositato né deliberato il deposito di una domanda di concordato;\n' +
        '\n' +
        'il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;\n' +
        '\n' +
        'ha adempiuto puntualmente agli obblighi di cui all’ordine/Contratto (All. 3) in base al quale il Credito è sorto;\n' +
        '\n' +
        'è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;\n' +
        '\n' +
        'all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito;\n' +
        '\n' +
        'i contratti da cui derivano i crediti ceduti con il presente atto sono stati validamente stipulati nell’osservanza delle disposizioni di legge vigenti ad essi applicabili, e non contengono clausole o disposizioni che impediscono o anche limitino il trasferimento o la cessione, in tutto o in parte, dei crediti che ne derivano;\n' +
        '\n' +
        '\n' +
        '\n' +
        "4.2 Le Parti convengono che la cessione del Credito viene effettuata pro-soluto e, per l'effetto, il Cedente viene liberato da ogni responsabilità in ordine alla solvibilità del Debitore Ceduto, rispetto a cui non presta alcuna garanzia.\n" +
        "4.3 Il Cedente si obbliga a manlevare e a tenere totalmente indenne il Cessionario da tutte le eventuali richieste per pretese oneri, danni e/o quant'altro avanzate da terzi a qualsiasi titolo ed in qualsiasi momento in ordine al Credito ceduto e si impegna in caso di procedimenti giudiziali e/o arbitrali conseguenti a tali richieste ovvero al fine di transigere tali pretese, a non compiere atti di remissione o rinuncia che possano pregiudicare la recuperabilità del Credito ceduto. Le Parti concordano che gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano i crediti o le garanzie accessoria e finalizzate all’accertamento di responsabilità restitutorie o risarcitorie saranno gestiti a cura e spese del Cedente, fermo restando che il Cedente dovrà prontamente informare il Cessionario di tali azioni e di ogni altra azione passiva che possa comunque interessare il Cessionario.\n" +
        "4.4 Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere.\n" +
        '\n' +
        '5. Notifica al Debitore Ceduto \n' +
        '5.1 Contestualmente alla sottoscrizione del presente Accordo, ' +
        this.invoice.cedenteName +
        " si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all'art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant'altro fosse necessario affinché i destinatari prendano debita nota dell'intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata.\n" +
        '\n' +
        '6. Risoluzione\n' +
        "6.1 Il Cessionario potrà risolvere il presente Accordo, ai sensi dell'art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dal presente Accordo.\n" +
        '6.1 Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo dei crediti ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute.\n' +
        '\n' +
        '7. Comunicazioni\n' +
        '7.1 Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:\n' +
        '\n' +
        'Se a FX12 \n' +
        'c.a. Rita Capitelli\n' +
        'Via Francesco Caracciolo 17 Napoli (NA)\n' +
        'PEC: fx12@pec.it \n' +
        '\n' +
        '\n' +
        '\n' +
        'Se a ' +
        this.invoice.cedenteName +
        '\n' +
        'c.a. ' +
        this.cedente.refName +
        '\n' +
        'Via ' +
        this.cedente.legalAddressStreet +
        '_' +
        this.cedente.legalAddressCity +
        '_' +
        this.cedente.legalAddressProvince +
        '_' +
        this.cedente.legalAddressCAP +
        '\n' +
        'PEC: ' +
        pecValidato +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '8. Miscellanea\n' +
        '8.1 Il presente Accordo è regolato dalla legge italiana.\n' +
        "8.2 Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di [-], intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria.\n" +
        '8.3 Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile\n' +
        '\n' +
        'Allegati:\n' +
        '\n' +
        'fattura n° ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        '\n' +
        'Frontespizio IBAN\n' +
        '\n' +
        'Ordine/Contratto d’acquisto\n' +
        '\n' +
        '\n' +
        '\n' +
        'Napoli, \n' +
        '\n' +
        'Letto, confermato e sottoscritto\n' +
        '\n' +
        '__________________________ __________________________\n' +
        'FX12 srl ' +
        this.invoice.cedenteName +
        ' \n' +
        'Rita Capitelli ' +
        this.cedente.refName +
        '\n' +
        '(Amministratore Unico) (Amministratore Unico)\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n';
      // tslint:disable-next-line:max-line-length
      // perchè venga rendeìrizzato dal canvas, htmlelement deve essere appeso come child al body (sarà successivamente rimosso)
      document.body.appendChild(contratto);
      const DATA = document.getElementById('htmlData');

      Utilities.downloadAsPDF(contratto, 'contratto', contrattoText);
      // rimuovo html element
      document.body.removeChild(contratto);
    });
  }

  aggiungiInvestitore() {
    const modalRef = this._modalService.open(ModalAddOriginatorComponent, {
      size: 'l',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.walletToModify = this.wallet;
    modalRef.componentInstance.invoice = this.invoice;
  }

  eliminaInvestitoreDaPercentuale(inv: string) {
    // console.log(inv);
    //  console.log(this.listaInvestitoriPagamento);
    //  console.log(this.listInvestitori);
    //  console.log(this.listaInvestitoriNonPaganti);
    const newListaInvestitoriPagamento = [];
    const newListaInvestitori = [];
    const newlistaInvestitoriNonPaganti = [];
    this.listaInvestitoriPagamento.forEach(element => {
      const inv2 = element.split('---')[0];
      if (inv != inv2) {
        newListaInvestitoriPagamento.push(element);
      } else {
        const per: number = +element.split('---')[1];
        this.percentualeSalvata = this.percentualeSalvata - per;
      }
    });
    this.listInvestitori.forEach(element => {
      const inv2 = element.split('---')[0];
      if (inv != inv2) {
        newListaInvestitori.push(element);
      }
    });
    this.listaInvestitoriNonPaganti.forEach(element => {
      if (inv != element[0]) {
        newlistaInvestitoriNonPaganti.push(element[0]);
      }
    });
    this.listaInvestitoriPagamento = newListaInvestitoriPagamento;
    this.listInvestitori = newListaInvestitori;
    this.listaInvestitoriNonPaganti = newlistaInvestitoriNonPaganti;

    // console.log(this.listaInvestitoriPagamento);
    // console.log(this.listInvestitori);
    //  console.log(this.listaInvestitoriNonPaganti);
    //  console.log(this.percentualeSalvata);

    this.percentualeCoperta = this.percentualeSalvata;
    /*
    this.invoice.listaInvestitori = this.listInvestitori;
    this.invoice.listaInvestitoriPagamento = this.listaInvestitoriPagamento;
    console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    */
  }

  corrente() {
    if (this.investitore == null || this.investitore == '') {
      return false;
    }
    if (this.firebase.loggedUser.id == this.investitore) {
      return true;
    }
    return false;
  }

  uploadFileNota() {
    const filePath = this.invoice.id + '-NC.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.invoice.status = Utilities.invoiceStatus[4];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.adminChangeStatusIndex(11);
          // this.sendMailToAdminAndServicer('getNotaCreditoCaricataFormalmente', 'Nota Di Credito Caricata ');

          // invio email di notifica
          // creo il body dell'email

          //
          const body =
            '{\n' +
            '    "to":"' +
            this.debitore.email +
            '",\n' +
            '    "firstname":"' +
            this.debitore.originatorSolicitorName +
            '",\n' +
            '    "lastname":"' +
            this.debitore.originatorSolicitorSurname +
            '",\n' +
            '    "subject":"Nuova nota di credito",\n' +
            '    "senderCompany":"FX12 Srl",\n' +
            '    "from":"support@incassaora.it",\n' +
            '    "senderName":"Support of Incassaora",\n' +
            '    "templateName":"newCreditNote",\n' +
            '    "denominazioneCedente":"' +
            this.cedente.denominazione +
            '",\n' +
            '    "denominiazioneDebitore":"' +
            this.debitore.name +
            '"\n' +
            '}';

          console.log('body=>', body);

          this.sendMail.sendEmailTemplateByBody(body);
        })
      )
      .subscribe();
  }

  notaCreditoVerified(verified: boolean, type: string) {
    switch (type) {
      case 'formal': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[5];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToOriginator('getNotaCreditoCaricataFormalmente', 'DA APPROVARE');
          this.adminChangeStatusIndex(12);
        } else {
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToFornitore('getNotaCreditoCaricataScartataFormalmente', 'Nota Credito', this.invoice.uid);
          this.adminChangeStatusIndex(10);
        }
        break;
      }
      case 'official': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.adminChangeStatusIndex(13);
        } else {
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToFornitore('getNotaCreditoCaricataScartataUfficialmente', 'Nota Credito', this.invoice.uid);
          this.adminChangeStatusIndex(10);
        }
        break;
      }
    }
  }

  bonificoVerified(verified: boolean, type: string) {
    switch (type) {
      case 'formal': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[8];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToAdminAndServicer('getBonificoCaricatoFormalmente', 'DA APPROVARE');
          this.adminChangeStatusIndex(15);
        } else {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToOriginator('getBonificoCaricatoScartatoFormalmente', 'Bonfifico');
          this.adminChangeStatusIndex(13);
        }
        break;
      }
      case 'official': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[9];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToFornitore('getBonificoCaricatoUfficialmente', 'Bonfifico', this.invoice.uid);
          this.adminChangeStatusIndex(16);
        } else {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToOriginator('getBonificoCaricatoScartatoUfficialmente', 'Bonfifico');
          this.adminChangeStatusIndex(13);
        }
        break;
      }
    }
  }

  verificaPagamentoInvestitori() {
    // Inserire controllo se tutti hanno pagato
    const array = this.listaInvestitoriDaPagare;

    console.log('Investitori=>', array);

    let count = 0;

    for (let i = 0; i < array.length; i++) {
      const elem = array[i];

      const b = elem.split('---')[2];
      if (b == 'V' || b == 'F') {
        count = count + 1;
      }
    }
    console.log('COUNT=>', count);
    if (count == 0) {
      console.log("Tutti gli investitori sono stati pagati o fattura precedente all'aggiornamento");
      this.adminChangeStatusIndex(37);
    }
  }
  PagamentoDaDebitoreAInvestor() {
    // console.log(this.invoice.listaInvestitoriDaPagare);
    // console.log(this.debitore);
    this.isLoading = true;

    if (!this.invoice.listaInvestitoriDaPagare) {
      this.listaInvestitoriDaPagare = this.invoice.listaInvestitoriPagamento;
      this.invoice.listaInvestitoriDaPagare = this.listaInvestitoriDaPagare;
      this.firebase.modInvoice(this.invoice);
    }

    // Inserire controllo se tutti hanno pagato
    const array = this.listaInvestitoriDaPagare;
    var count = 0;
    for (let i = 0; i < array.length; i++) {
      var elem = array[i];

      var b = elem.split('---')[2];
      if (b == 'V' || b == 'F') {
        count = count + 1;
      }
    }
    if (count == 0) {
      alert("Tutti gli investitori sono stati pagati o fattura precedente all'aggiornamento");
      this.adminChangeStatusIndex(37);

      this.isLoading = false;
      return;
    }

    const fondi = 10000000;
    // Importo finale senza sconto
    const prezzofinale = this.invoice.importoFattura;
    var prezzoCalcolo = this.invoice.importoFattura;
    // console.log('AMOUNT:' + prezzofinale);

    if (fondi > prezzofinale) {
      for (let i = 0; i < array.length; i++) {
        var elem2 = array[i];
        const inv = elem2.split('---')[0];
        const per: number = +elem2.split('---')[1];
        const bool = elem2.split('---')[2];
        // console.log(per);
        // console.log(prezzofinale);
        prezzoCalcolo = (prezzofinale * per) / 100;
        // console.log(prezzoCalcolo);
        prezzoCalcolo = prezzoCalcolo * 100;
        // console.log(prezzoCalcolo);
        const valoreForLemonway: number = Math.trunc(prezzoCalcolo);
        // console.log(valoreForLemonway);

        if (bool == 'V' || bool == 'F') {
          // "3322116655"
          const comment = 'Refund Investor';
          this.lemonway.transaction(valoreForLemonway, inv, this.debitore.originatorPIva, comment).subscribe(data => {
            try {
              if (data.transaction.status == 3) {
                const newBool = 'R';
                elem2 = inv + '---' + per + '---' + newBool;
                array[i] = elem2;
                this.listaInvestitoriDaPagare = array;
                this.invoice.listaInvestitoriDaPagare = this.listaInvestitoriDaPagare;
                this.firebase.modInvoice(this.invoice);
                alert(
                  'Transazione verso: ' +
                    inv +
                    ' eseguita, se ci sono altri investitori procedo con la prossima operazione'
                );
              } else {
                alert('Errore nella transazione verso: ' + inv);
              }
            } catch (e) {
              alert('Errore nella transazione verso: ' + inv + ' Errore: ' + data.error.message);
            }
          });
        }
      }
    } else {
      alert(
        "ALERT: Fondi non sufficienti a coprire l'importo richiesto.\n Effettuare una ricarica oppure contattare l'amministrazione"
      );
    }
  }

  contrattoVerified(verified: boolean) {
    if (verified) {
      this.firebase.modInvoice(this.invoice);
      // this.sendMailToAdminAndServicer('getContrattoCaricatoFormalmente', 'Contratto Approvato');

      // invio email di notifica
      // creo il body dell'email
      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "subject":"Nuovo contratto",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"newContract",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.name +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '"\n' +
        '}';

      // console.log('body=>',body);
      this.sendMail.sendEmailTemplateByBody(body);

      // ************** INVIO PEC CON CONTRATTO *************************

      // creo il body dell'email
      const bodyPEC =
        '{\n' +
        '    "to":"' +
        this.cedente.assignorPec +
        '",\n' +
        '    "to_name":"' +
        this.cedente.name +
        '",\n' +
        '    "subject":"Nuovo contratto",\n' +
        '    "body":"Spett.le ' +
        this.cedente.name +
        ',<br> In allegato trova il documento di cessione controfirmato.<br><br>Team of IncassaOra",\n' +
        '    "filename":"' +
        this.filename +
        '",\n' +
        '    "attachmentBase64":"' +
        this.file64Encode +
        '"\n' +
        '}';

      console.log('bodyPEC=>', bodyPEC);
      this.sendMail.sendPecByBody(bodyPEC);

      // ****************************************************************

      this.adminChangeStatusIndex(32);
    } else {
      this.sendMailToOriginator('getContrattoCaricatoScartatoFormalmente', 'Contratto  Dismesso');
      this.adminChangeStatusIndex(30);
    }
  }

  chiudiFattura() {
    this.sendMailToFornitore('getBonificoCaricataFormalmente', 'Bonfico Caricato', this.invoice.uid);
    this.invoice.status = Utilities.invoiceStatus[8];
    this.firebase.modInvoice(this.invoice);
    this.activeModal.dismiss();
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];

    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    this.fileToUpload = file;
    console.log(file);

    this.filename = file.name;
    const typeFile = file.type;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      this.file64Encode = reader.result as string;
      const strReplace = 'data:' + typeFile + ';base64,';
      this.file64Encode = this.file64Encode.replace(strReplace, '');
      console.log(this.file64Encode);
    };
  }

  scaricaContratto() {
    // metodo che si basa sulla generazione del pdf dobbiamo vederlo con gaetano cosi da farlo
    // in maniera rapida
  }

  uploadFileBonifico() {
    const filePath = this.invoice.id + '-BON.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.invoice.status = Utilities.invoiceStatus[7];
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaBonifico = pipe.transform(today, 'yyyy-MM-dd');
          this.firebase.modInvoice(this.invoice);
          this.firebase.chiudiFattura(this.wallet.id, this.invoice.importoFattura, this.invoice.scontoAccettato);
          this.adminChangeStatusIndex(14);
          this.activeModal.dismiss();
        })
      )

      .subscribe();
  }

  uploadFileContratto() {
    const filePath = this.invoice.id + '-CONTRATTOFIRMATO.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContratto = pipe.transform(today, 'yyyy-MM-dd');
          this.firebase.modInvoice(this.invoice);
          if (this.invoice.status_index == 31) {
            this.uploading = true;
            return;
          }
          this.adminChangeStatusIndex(31);

          this.activeModal.dismiss();
        })
      )

      .subscribe();
  }

  open(name: string, fileToUpload: number) {
    const modalRef = this._modalService.open(MODALS[name]);
    modalRef.result.then(
      result => {
        if (fileToUpload == 0) {
          this.uploadFileNota();
        }
        if (fileToUpload == 1) {
          this.uploadFileBonifico();
        }
        if (fileToUpload == 2) {
          this.uploadFileContratto();
        }
      },
      reason => {}
    );
  }

  private sendMailToOriginator(typology: string, subject: string): void {
    this.firebase
      .getWalletfromID(this.invoice.walletId)
      .then(doc => {
        if (doc.exists) {
          const wallet = doc.data() as Wallet;
          this.firebase
            .getUserByID(wallet.originatorUId)
            .then(doc2 => {
              if (doc2.exists) {
                const walletName = doc.data().walletName;
                const email = doc2.data().email;
                const mailText = Utilities.getMailText(typology, walletName);
                this.mailerService.sendEmail(email, subject + walletName, mailText).subscribe(() => {});
                this.firebase.createEmailLog(email, typology);
              } else {
              }
            })
            .catch(error => {});
        } else {
        }
      })
      .catch(error => {});
  }

  private sendMailToFornitore(typology: string, subject: string, userId: string): void {
    this.firebase
      .getUserByID(userId)
      .then(doc2 => {
        if (doc2.exists) {
          const email = doc2.data().email;
          const mailText = Utilities.getMailText(typology, null);
          this.mailerService.sendEmail(email, subject, mailText).subscribe(() => {});
          this.firebase.createEmailLog(email, typology);
        } else {
        }
      })
      .catch(error => {});
  }

  private sendMailToInvoicer(typology: string, subject: string, userId: string): void {
    this.firebase
      .getUserByID(userId)
      .then(doc2 => {
        if (doc2.exists) {
          const email = doc2.data().email;
          const mailText = Utilities.getMailText(typology, null);
          this.mailerService.sendEmail(email, subject, mailText).subscribe(() => {});
          this.firebase.createEmailLog(email, typology);
        } else {
        }
      })
      .catch(error => {});
  }

  private sendMailToAdminAndServicer(typology: string, subject: string): void {
    const mailText = Utilities.getMailText(typology, null);
    let mailingList: string[] = [];
    this.firebase.returnEmailListByRole(Utilities.userType[1]).subscribe(data => {
      mailingList = Utilities.formatArray(data);
      if (mailingList.length > 0) {
        this.mailerService.multisendEmail(mailingList, subject, mailText).subscribe(() => {});
        this.firebase.createMultiEmailLog(mailingList, typology);
      }
    });

    this.firebase.returnEmailListByRole(Utilities.userType[6]).subscribe(data => {
      mailingList = Utilities.subtractArray(mailingList, Utilities.formatArray(data));
      if (mailingList.length > 0) {
        this.mailerService.multisendEmail(mailingList, subject, mailText).subscribe(() => {});
        this.firebase.createMultiEmailLog(mailingList, typology);
      }
    });
  }

  scaricaContrattoPDF() {
    var iban = '___________________________';
    if (this.cedente.iban) {
      iban = this.cedente.iban;
    }

    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center',
          margin: [5, 10]
        },

        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
      ],

      footer: (currentPage, pageCount) => [
        {
          text: 'FX12 s.r.l iscritta nell’albo speciale delle Start up innovative ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2],
          bold: true
        },
        {
          text:
            'Capitale sociale Euro 10.0000 i.v., Registro Imprese Napoli e Codice Fiscale N. 09528731210, R.E.A. Napoli N. 1039449, Part. IVA 09528731210 ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: 'Sede legale: Via Caracciolo, 17, 80122 Napoli.',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text:
            'Sede operativa: Via Coroglio, 57/104, 80124 Napoli c/o Campania NewSteel | Tel: +39 081 7352503 Email: desk@fx12.it PEC fx12@pec.it',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: '\nPagina  ' + currentPage.toString() + ' di ' + pageCount,
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        },
        {
          text: '_',
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        }
      ],

      content: [
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: this.cedente.name,
          margin: [0, 5]
        },
        {
          text:
            this.cedente.legalAddressStreet +
            ' ' +
            this.cedente.legalAddressCity +
            ' ' +
            this.cedente.legalAddressProvince,
          margin: [0, 10]
        },
        {
          text: 'Alla cortese attenzione',
          margin: [0, 10],
          decoration: 'underline',
          italics: true
        },
        {
          text:
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname),
          margin: [0, 5]
        },
        {
          text: this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori, ',
          italics: true,
          margin: [0, 10]
        },
        {
          text:
            'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.',
          italics: true,
          margin: [0, 10]
        },
        {
          text: 'Oggetto: Contratto di cessione del credito ',
          bold: true,
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori: ',
          margin: [0, 5]
        },
        {
          text: 'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue: '
        },
        {
          text: 'ACCORDO',
          bold: true,
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: 'Tra',
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text:
            'FX12 s.r.l., con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 in persona della Dott.ssa Rita Capitelli, che sottoscrive il presente accordo in qualità di amministratore e legale rappresentante (di seguito, “FX12” o il “Cessionario”) ',
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- da una parte -',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            this.cedente.legalName +
            ', con sede legale in ' +
            this.cedente.legalAddressCity +
            ' (' +
            this.cedente.legalAddressProvince +
            ')' +
            ', ' +
            this.cedente.legalAddressStreet +
            ' CF. ' +
            this.cedente.fiscalCode +
            ' e P.Iva ' +
            this.cedente.pIva +
            ', numero di iscrizione al Registro delle Imprese di ' +
            this.cedente.legalAddressProvince +
            ' n. ' +
            this.cedente.REA_CODE +
            ', in persona del Sig. ' +
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname) +
            ' ' +
            ', che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “ ' +
            this.cedente.denominazione +
            '” o il “Cedente”);',
          margin: [0, 5]
        },
        {
          text: '- dall’altra parte - ',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            'FX12 e ' +
            this.cedente.denominazione +
            ', d’ora in avanti congiuntamente denominate le "Parti", e ciascuna singolarmente una "Parte");',
          margin: [0, 2],
          alignment: 'justify',
          pageBreak: 'after'
        },

        {
          margin: [0, 20, 0, 0],
          text: 'PREMESSO CHE',
          alignment: 'center',
          bold: true
        },
        //PROSSIMO FOGLIO
        {
          text: ' '
        },
        {
          type: 'upper-alpha',
          ol: [
            {
              text:
                'FX12 è una start up innovativa che progetta e realizza piattaforme web finalizzate allo sviluppo di servizi di finanza digitale dedicati all’accelerazione dei pagamenti ed alla cessione di fatture commerciali; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.wallet.originatorName +
                ' è un’azienda dotata di un elevato merito creditizio, interessata ad accelerare i pagamenti ai propri fornitori, e che a tal fine ha aderito alla piattaforma Incassa Ora® di FX12 (di seguito anche il “Debitore Ceduto”);',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' è il fornitore di ' +
                this.wallet.originatorName +
                ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di ' +
                this.wallet.originatorName,
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.wallet.originatorName +
                ', in particolare, si è impegnata a corrispondere a ' +
                this.cedente.denominazione +
                ' un importo, da liquidarsi entro ' +
                this.wallet.paymentDays +
                ' giorni dall’emissione della fattura; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' ha manifestato l’intenzione di cedere e trasferire a FX12 attraverso la piattaforma Incassa Ora® il singolo credito risultante dalla fattura n. ' +
                this.invoice.numFattura +
                ' del ' +
                myDataConversion(this.invoice.dataFattura) +
                '; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text: 'FX12 intende acquistare il credito secondo le modalità e termini di cui al presente contratto. ',
              alignment: 'justify',
              margin: [0, 2]
            }
          ]
        },
        {
          text:
            "TUTTO CIÒ PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente accordo (l'Accordo), le Parti convengono quanto segue:",
          margin: [0, 20],
          alignment: 'justify'
        },
        {
          ol: [
            [
              { text: 'Premesse ', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text: 'Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Oggetto', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'In forza della sottoscrizione del presente Accordo, il Cedente cede a titolo oneroso pro-soluto al Cessionario, che accetta, il credito risultante dalla fattura n° ' +
                      this.invoice.numFattura +
                      ' del ' +
                      myDataConversion(this.invoice.dataFattura) +
                      ' di ' +
                      formatCurrency(this.invoice.importoFattura, this.locale, '€') +
                      ' (di seguito, il “Credito”) che vanta nei confronti di ' +
                      this.wallet.originatorName,
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n° ' +
                      this.invoice.numFattura +
                      ' del  ' +
                      myDataConversion(this.invoice.dataFattura) +
                      ' (All. 1).',
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Dalla data di sottoscrizione del presente Accordo, quindi, FX12 diventa a tutti gli effetti di legge titolare del Credito ceduto. Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c..',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Prezzo', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'A fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente:un importo pari a ' +
                      formatCurrency(
                        myRound(
                          this.invoice.importoFattura -
                            (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100
                        ),
                        this.locale,
                        '€'
                      ) +
                      ' , entro ' +
                      this.wallet.paymentDays +
                      ' giorni lavorativi dalla sottoscrizione del presente Accordo.',
                    margin: [0, 2],
                    pageBreak: 'after',
                    alignment: 'justify'
                  },
                  {
                    margin: [0, 22, 0, 2],
                    text:
                      'I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
                      iban +
                      ' intestato a ' +
                      this.cedente.denominazione +
                      ' (All. 2).'
                  }
                ]
              }
            ],
            [
              { text: 'Garanzie e obblighi delle Parti', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  [
                    { text: 'Il Cedente garantisce che', margin: [0, 5] },
                    {
                      ul: [
                        {
                          text:
                            'non è in stato di insolvenza, non esistono fatti o circostanze che potrebbero esporla a eventuali procedure concorsuali e non ha depositato né deliberato il deposito di una domanda di concordato;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'ha adempiuto puntualmente agli obblighi di cui all’ordine/Contratto (All. 3) in base al quale il Credito è sorto;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'i contratti da cui derivano i crediti ceduti con il presente atto sono stati validamente stipulati nell’osservanza delle disposizioni di legge vigenti ad essi applicabili, e non contengono clausole o disposizioni che impediscono o anche limitino il trasferimento o la cessione, in tutto o in parte, dei crediti che ne derivano;',
                          margin: [0, 2],
                          alignment: 'justify'
                        }
                      ]
                    }
                  ],
                  {
                    text:
                      "Le Parti convengono che la cessione del Credito viene effettuata pro-soluto e, per l'effetto, il Cedente viene liberato da ogni responsabilità in ordine alla solvibilità del Debitore Ceduto, rispetto a cui non presta alcuna garanzia.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Il Cedente si obbliga a manlevare e a tenere totalmente indenne il Cessionario da tutte le eventuali richieste per pretese oneri, danni e/o quant'altro avanzate da terzi a qualsiasi titolo ed in qualsiasi momento in ordine al Credito ceduto e si impegna in caso di procedimenti giudiziali e/o arbitrali conseguenti a tali richieste ovvero al fine di transigere tali pretese, a non compiere atti di remissione o rinuncia che possano pregiudicare la recuperabilità del Credito ceduto. Le Parti concordano che gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano i crediti o le garanzie accessoria e finalizzate all’accertamento di responsabilità restitutorie o risarcitorie saranno gestiti a cura e spese del Cedente, fermo restando che il Cedente dovrà prontamente informare il Cessionario di tali azioni e di ogni altra azione passiva che possa comunque interessare il Cessionario.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere.",
                    margin: [0, 2],
                    alignment: 'justify',
                    pageBreak: 'after'
                  }
                ]
              }
            ],

            //PROSSIMO BLOCCO

            [
              { text: 'Notifica al Debitore Ceduto', bold: true, decoration: 'underline', margin: [0, 22, 0, 2] },
              {
                ol: [
                  {
                    text:
                      'Contestualmente alla sottoscrizione del presente Accordo, ' +
                      this.cedente.denominazione +
                      " si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all'art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant'altro fosse necessario affinché i destinatari prendano debita nota dell'intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata.",
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Risoluzione', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      "Il Cessionario potrà risolvere il presente Accordo, ai sensi dell'art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dal presente Accordo.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo dei crediti ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute.',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Comunicazioni', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:',
                    margin: [0, 5, 0, 15],
                    alignment: 'justify'
                  }
                ]
              },
              {
                text: [
                  { text: 'Se a FX12', bold: true, alignment: 'left' },
                  {
                    text:
                      '                                                            Se a ' + this.cedente.denominazione,
                    bold: true,
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'c.a. Rita Capitelli', alignment: 'left' },
                  {
                    text:
                      '                                                 c.a. ' +
                      this.cedente.refName +
                      ' ' +
                      (this.cedente.refCogn && this.cedente.refCogn != ''
                        ? this.cedente.refCogn
                        : this.cedente.surname),
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'Via Francesco Caracciolo 17 Napoli (NA) ', alignment: 'left' },
                  {
                    text:
                      '     ' +
                      this.cedente.legalAddressStreet +
                      '  ' +
                      this.cedente.legalAddressCity +
                      ' (' +
                      this.cedente.legalAddressProvince +
                      ')',
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'PEC: fx12@pec.it', alignment: 'left' },
                  {
                    text: '                                                 PEC: ' + this.cedente.assignorPec,
                    margin: [0, 0, 20, 0]
                  }
                ]
              }
            ],
            [
              { text: 'Miscellanea', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text: 'Il presente Accordo è regolato dalla legge italiana.',
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di Napoli, intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria.",
                    margin: [0, 2],
                    alignment: 'justify',
                    pageBreak: 'after'
                  },
                  {
                    text:
                      'Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile',
                    margin: [0, 22, 0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ]
          ]
        },
        [
          { text: 'Allegati', bold: true, decoration: 'underline', margin: [0, 20, 0, 0] },
          {
            ol: [
              {
                text: 'fattura n° ' + this.invoice.numFattura + ' del ' + myDataConversion(this.invoice.dataFattura),
                margin: [0, 2],
                alignment: 'justify'
              },
              {
                text: 'Frontespizio IBAN',
                margin: [0, 2],
                alignment: 'justify'
              },
              {
                text: 'Ordine/Contratto d’acquisto',
                margin: [0, 2],
                alignment: 'justify'
              }
            ]
          }
        ],

        {
          text: 'Napoli,   ',
          margin: [0, 20, 0, 0]
        },
        {
          text: 'Letto, confermato e sottoscritto',
          margin: [0, 10, 0, 10]
        },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '50%',
              text: '\n\n\n__________________________________\nFX12\nRita Capitelli\n(Amministratore Unico)'
            },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              width: '50%',
              text:
                '\n\n\n__________________________________\n' +
                this.cedente.denominazione +
                '\n' +
                this.cedente.refName +
                ' ' +
                (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname) +
                '\n(Legale rappresentante)'
            }
          ],
          // optional space between columns
          columnGap: 10
        }
      ],
      images: {
        logo: 'https://panel.incassaora.it/assets/logoPDF.png'
      },

      styles: {}
    };

    pdfMake.createPdf(docDefinition).open();
  }

  addInvestorProd23() {
    console.log(this.associaInvestitore.value.pivaInvestitore);
    console.log(this.associaInvestitore.value.percentuale);
    // verifico se vi sono investitori inseriti

    const currentInv = this.associaInvestitore.value.pivaInvestitore;
    const percInserita: number = parseFloat(this.associaInvestitore.value.percentuale.toFixed(16));

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      // console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[0] == currentInv) {
          investorPresent = true;
          investorPercIfPresent = arrInv[1];
          listaInvestitoriPagamento.splice(index, 1);
          listaInvestitori.splice(index, 1);
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente - percInserita;
      console.log('percRimanente Relativa =>', percRimanente);

      if (percRimanente < 0) {
        alert(
          'Impossibile inserire questo valore. La percentuale deve essere uguale o inferiore alla rimanenza scoperta.'
        );
        return;
      } else {
        // qualora l'investitore sia già presente bisogna aggiornare i dati
        let percAggiornata = 0;
        console.log('percInserita =>', percInserita);
        console.log('investorPercIfPresent =>', investorPercIfPresent);
        if (investorPresent) {
          percAggiornata = parseFloat(investorPercIfPresent + '') + parseFloat(percInserita + '');
        } else {
          percAggiornata = percInserita;
        }

        console.log('percAggiornata =>', percAggiornata);

        // aggiungo l'investitore all'array di cui sopra e aggiorno la fattura.
        listaInvestitoriPagamento.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata + '---F');
        listaInvestitoriDaPagare.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata + '---F');
        listaInvestitori.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata);

        // creo l'oggetto per l'aggiornamento dello specifico campo
        const obj = {
          listaInvestitoriPagamento: listaInvestitoriPagamento,
          listaInvestitoriDaPagare: listaInvestitoriDaPagare,
          listaInvestitori: listaInvestitori
        };

        console.log('Oggetto=>', obj);
        this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
        // aggiorno invoice
        this.percentualeScoperta = percRimanente;

        this.recalculateCopertura();
      }
    });
  }

  approvaPropInvestorProd23(investor: string) {
    const investorArr = investor.split('---');
    console.log(investorArr);
    // verifico se vi sono investitori inseriti

    const currentInv = investorArr[0];
    console.log('currentInv=>', currentInv);
    const percInserita: number = parseFloat(investorArr[1]);

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];
    let listaProposteInvestimento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      // console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }
      if (currentInvoice.listaProposteInvestimento) {
        listaProposteInvestimento = currentInvoice.listaProposteInvestimento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[0] == currentInv) {
          investorPresent = true;
          investorPercIfPresent = arrInv[1];

          listaInvestitoriPagamento.splice(index, 1);
          listaInvestitori.splice(index, 1);
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente - percInserita;
      console.log('percRimanente Relativa =>', percRimanente);

      if (percRimanente < 0) {
        alert(
          'Impossibile inserire questo valore. La percentuale deve essere uguale o inferiore alla rimanenza scoperta.'
        );
        return;
      } else {
        // qualora l'investitore sia già presente bisogna aggiornare i dati
        let percAggiornata = 0;
        console.log('percInserita =>', percInserita);
        console.log('investorPercIfPresent =>', investorPercIfPresent);
        if (investorPresent) {
          percAggiornata = parseFloat(investorPercIfPresent + '') + parseFloat(percInserita + '');
        } else {
          percAggiornata = percInserita;
        }

        console.log('percAggiornata =>', percAggiornata);

        // aggiungo l'investitore all'array di cui sopra e aggiorno la fattura.
        listaInvestitoriPagamento.push(currentInv + '---' + percAggiornata + '---F');
        listaInvestitoriDaPagare.push(currentInv + '---' + percAggiornata + '---F');
        listaInvestitori.push(currentInv + '---' + percAggiornata);

        listaProposteInvestimento.forEach((invProp, index) => {
          const invArr = invProp.split('---');
          if (invArr[0] == currentInv) {
            listaProposteInvestimento[index] = currentInv + '---' + percInserita + '---S';
          }
        });

        // creo l'oggetto per l'aggiornamento dello specifico campo
        const obj = {
          listaInvestitoriPagamento: listaInvestitoriPagamento,
          listaInvestitoriDaPagare: listaInvestitoriDaPagare,
          listaInvestitori: listaInvestitori,
          listaProposteInvestimento: listaProposteInvestimento
        };

        console.log('Oggetto=>', obj);
        this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
        // aggiorno invoice
        this.percentualeScoperta = percRimanente;

        this.recalculateCopertura();
      }
    });
  }

  eliminaInvestitoreProd23(investor: any) {
    console.log(investor);
    const investorArr = investor;
    // verifico se vi sono investitori inseriti

    let currentInv = investorArr[0];
    console.log('currentInv=>', currentInv);
    const percInserita: number = parseFloat(investorArr[1]);

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      // console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // per ognuna delle liste sopra bisogna togliere l'investitore corrente e aggiornare il dato sul db
      listaInvestitoriPagamento.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitoriPagamento.splice(index, 1);
        }
      });

      listaInvestitori.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitori.splice(index, 1);
        }
      });

      listaInvestitoriDaPagare.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
      });

      // aggiorno fattura su db
      // creo l'oggetto per l'aggiornamento dello specifico campo
      const obj = {
        listaInvestitoriPagamento: listaInvestitoriPagamento,
        listaInvestitoriDaPagare: listaInvestitoriDaPagare,
        listaInvestitori: listaInvestitori
      };

      console.log('Oggetto=>', obj);
      this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente + percInserita;
      console.log('percRimanente Relativa =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      this.recalculateCopertura();
    });
  }

  recalculateCopertura() {
    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      // console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;

      this.listaInvestitoriNonPaganti = [];
      this.listaInvestitoriPagato = [];
      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[2] == 'F') {
          this.listaInvestitoriNonPaganti.push([arrInv[0], arrInv[1]]);
        }
        if (arrInv[2] == 'V') {
          this.listaInvestitoriPagato.push([arrInv[0], arrInv[1]]);
        }
      });

      this.listInvestorOfWalletNonPaganti = [];
      this.firebase.getUsersByRole(Utilities.userType[5]).subscribe(data => {
        data.forEach(investor => {
          if (this.verificaPagamenti(investor.investorPIva) == false && investor.investorPIva != '') {
            this.listInvestorOfWalletNonPaganti.push([investor.investorPIva, investor.email]);
          }
        });
        // console.log('listInvestorOfWalletNonPaganti=>',this.listInvestorOfWalletNonPaganti);
      });

      if (this.firebase.isInvestor()) {
        this.investitorePagato = this.verificaPagamenti(this.firebase.loggedUser.investorPIva);
        // console.log("l'investitore corrente " + this.firebase.loggedUser.investorPIva + '---' + this.investitorePagato);
      }
      if (this.firebase.isInvestor()) {
        this.listInvestitori.forEach(investor => {
          const inv = investor.split('---')[0];
          const per: number = +investor.split('---')[1];
          //console.log(inv);
          //console.log(per);
          const bool = investor.split('---')[2];
          if (inv == this.firebase.loggedUser.investorPIva) {
            this.investitoreCorrentePercentuale = per;
          }
        });
      }

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
    });
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Caricamento documento</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Sei sicuro di aver selezionato il file corretto? Se hai problemi con un file caricato contatta desk@fx12.it</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Indietro</button>
      <button type="button" class="btn btn-primary" (click)="modal.close('Ok click')">Carica file</button>
    </div>
  `
})
export class NgbdModalConfirmComponent {
  constructor(public modal: NgbActiveModal) {}
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};

function myRound(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

function myDataConversion(date) {
  try {
    date = date + 'T00:00:00';
    const newDate = new Date(date);
    const datePipe = new DatePipe('it-IT');
    const latest_date = datePipe.transform(newDate, 'dd/MM/yyyy');
    return latest_date;
  } catch (e) {
    console.warn('Errore=>', e);
    return date;
  }
}
