import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ClipboardService } from 'ngx-clipboard';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import { environment } from '@env/environment';
import * as _ from 'lodash';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Input() userType: string;

  public roles: string[];

  public wallets: Observable<any[]>;
  public walletList: Observable<any[]>;
  baseUrl: string;
  isCopied = false;
  walletsItems: any[];
  filteredWalletsItems: any[] = [];
  regions: string[];
  sectors: string[];
  filters = {};
  filterRegion = '';
  filterFinancing = 0;
  filterSector = '';
  filterBank = '';
  filterStatoValore = 'Attivo';
  walletCreationForm!: FormGroup;
  isSubmitted = false;
  staticAlertClosed = false;
  successMessage: string;
  autohide = true;

  page: number;
  collectionSize: number;
  pageSize: number;
  sub: Subscription;
  private _success = new Subject<string>();

  constructor(
    public firebase: FirebaseTranchedService,
    private _clipboardService: ClipboardService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.regions = Utilities.regions;
    this.baseUrl = environment.baseUrl;
    this.roles = Utilities.userType;
  }

  ngOnInit() {
    setTimeout(() => (this.staticAlertClosed = true), 5000);

    this._clipboardService.copyResponse$.subscribe(re => {
      if (re.isSuccess) {
        this.isCopied = true;
      }
    });
    this.wallets = this.firebase.getWallets();
    this.walletList = this.firebase.getWallets();
    this.page = 1;
    this.sub = this.wallets.subscribe(data => {
      this.walletsItems = data;
      this.filterForRole();

      this.applyFilters();
      this.getDaysLeft(this.filteredWalletsItems);
    });

    this.pageSize = 10000000;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getDaysLeft(array: any[]) {
    for (const wallet of array) {
      Utilities.getDaysLeft(wallet);
    }
  }

  filterForRole() {
    if (this.firebase.isOriginator()) {
      this.walletsItems = _.filter(this.walletsItems, item => item.originatorUId === this.firebase.loggedUser.id);
    } else if (this.firebase.isCompany()) {
      this.walletsItems = _.filter(this.walletsItems, item => {
        return this.firebase.loggedUser.listWallet.includes(item.id);
      });
    }
  }

  close() {}

  onCopyFailure() {}

  public changeSuccessMessage() {
    this._success.next(`Link copiato!`);
  }

  showDetailPage(id: string) {
    if (this.firebase.isAdmin()) {
      this.router.navigate(['/home/wallet-details/' + id]);
    } else if (this.firebase.isOriginator()) {
      this.router.navigate(['/originator/wallet-details/' + id]);
    } else {
      this.router.navigate(['/cedente/wallet-details/' + id]);
    }
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    if (rule == '') {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val == rule;

      this.applyFilters();
    }
  }

  /// filter  numbers lower than rule
  filterGreaterThan(property: string, rule: number) {
    if (rule == 0) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val >= rule;
      this.applyFilters();
    }
  }

  /// filter  numbers greater than rule
  filterLowerThan(property: string, rule: any) {
    if (rule == 0) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val <= rule;
      this.applyFilters();
    }
  }

  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val;
      this.applyFilters();
    }
  }

  public filterStato(stato: string) {
    this.applyFilters();
  }

  removeFilter(property: string) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilters();
  }

  private applyFilters() {
    // TODO eliminare la cosa attivo esaurito e piuttosto fare una query per bene che prende i wallet con originatorId pari a quello preso dalla route altrimenti restituirà tutti quelli
    // a cui si è legato l'investor
    this.filteredWalletsItems = this.walletsItems;
    if (this.filterStatoValore === 'Attivo') {
      this.filteredWalletsItems = _.filter(this.filteredWalletsItems, item => !item.closed);
    } else {
      this.filteredWalletsItems = _.filter(this.filteredWalletsItems, item => item.closed);
    }
    this.collectionSize = this.filteredWalletsItems.length;
  }
}
