import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilities } from '@app/utilities/Utilities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailerService {
  sendEmailUrl = Utilities.sendMailUrl;
  sendEmailTemplateUrl = Utilities.sendEmailTemplateUrl;
  sendPecUrl = Utilities.sendPecUrl;
  mailBody = Utilities.body;
  multiMailBody = Utilities.multiBody;
  multiBodyTemplate = Utilities.multiBodyTemplate;

  constructor(private http: HttpClient) {}

  sendEmail(to: string, subject: string, text: string): Observable<any> {
    this.mailBody.to = to;
    this.mailBody.subject = subject;
    this.mailBody.html = text;
    return this.http.post<any>(this.sendEmailUrl, this.mailBody);
  }

  sendEmailNewUser(to: string): Observable<any> {
    this.mailBody.to = to;
    this.mailBody.subject = 'FX12';
    this.mailBody.html = 'Ti diamo il benvenuto nel nuovo sistema FX12';
    return this.http.post<any>(this.sendEmailUrl, this.mailBody);
  }

  multisendEmail(recipients: string[], subject: string, text: string): Observable<any> {
    this.multiMailBody.to = recipients;
    this.multiMailBody.subject = subject;
    this.multiMailBody.html = text;
    return this.http.post<any>(this.sendEmailUrl, this.multiMailBody);
  }

  sendEmailTemplate(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.multiBodyTemplate.to = params.to;
    this.multiBodyTemplate.firstname = params.firstname;
    this.multiBodyTemplate.lastname = params.lastname;
    this.multiBodyTemplate.subject = params.subject;
    this.multiBodyTemplate.templateName = params.templateName;

    const body =
      '{\n' +
      '    "to":"gaetano.bitonte@gmail.com",\n' +
      '    "firstname":"Gaetano",\n' +
      '    "lastname":"Bitonte",\n' +
      '    "subject":"Registrazione IncassaOra",\n' +
      '    "senderCompany":"FX12 Srl",\n' +
      '    "from":"support@incassaora.it",\n' +
      '    "senderName":"Support of Incassaora",\n' +
      '    "templateName":"registrationSuccessFull"\n' +
      '}';
    console.log('Invio email URL=>', this.sendEmailTemplateUrl);
    this.http.post<any>(this.sendEmailTemplateUrl, this.multiBodyTemplate, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  sendEmailTemplateByBody(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    console.log('Invio email URL=>', this.sendEmailTemplateUrl);
    this.http.post<any>(this.sendEmailTemplateUrl, body, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  sendPecByBody(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    console.log('Invio PEC URL=>', this.sendPecUrl);
    this.http.post<any>(this.sendPecUrl, body, httpOptions).subscribe(data => {
      console.log('response=>', data);
    });
  }
} // class
